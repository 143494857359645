import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import IMDatePicker from "../../ui/IMDatePicker";
import { IMLocationPicker } from "../../ui/IMLocationPicker";
import { IMPhoto } from "../../../common";
import { IMImagesTableCell } from "../../ui/IMTable";
import { Card, CardBody } from "reactstrap";
import { IMToggleSwitchComponent } from "../../../common";
import {
  GoogleMap,
  InfoWindow,
  Marker
} from "@react-google-maps/api";

import "../../ui/styles.css";

const baseAPIURL = require("../../config").baseAPIURL;

const UpdateBusinessView = (props) => {
  let { bid } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [originalData, setOriginalData] = useState(null);
  const [modifiedNonFormData, setModifiedNonFormData] = useState({});
  const [userDocument, setUserDocument] = useState();
  const [activeMarker, setActiveMarker] = useState(null);
  const [business1, setBusiness] = useState([]);
  const [markers11, setMarkers11] = useState([]);
  const [map, setMap] = React.useState(null)


// function handleLoad(map) {
//   mapRef.current = map;
// }

// function handleCenter() {
//   if (!mapRef.current) return;

//   const newPos = mapRef.current.getCenter().toJSON();
//   setPosition(newPos);
// }

  useEffect(() => {
    fetch(baseAPIURL + "business/" + bid)
      .then((response) => response.json())
      .catch((err) => {
        console.log(err);
      })
      .then((data) => {
        const business = [{
          "saferPointName": data.saferPointName, 'order_lat': data.businessLat, 'order_lng': data.businessLng
        }];
        console.log("original data", data);
        setOriginalData(data);
        if (data) {
          initializeModifieableNonFormData(data);
          userDocFunc();
        }
        // setIsLoading(false);
      });
  }, [bid]);

  const userDocFunc = () => {
    fetch(baseAPIURL + "business/document/" + bid)
      .then((response) => response.json())
      .then((resp) => {
        if (resp.status == 200) {
          setUserDocument(resp.profilePictureURL);
          setIsLoading(false);
        }
      });
  };
  useEffect(() => {
    console.log('userDocument123', userDocument);
  }, [userDocument]);
  const initializeModifieableNonFormData = (originalData) => {
    var nonFormData = {
      location: originalData.location,
    };
    // if (originalData.createdAt) {
    //     nonFormData['createdAt'] = new Date(originalData.createdAt)
    // }
    if (originalData.profilePictureURL) {
      nonFormData["profilePictureURL"] = originalData.profilePictureURL;
    }
    if (originalData.photos) {
      nonFormData["photos"] = originalData.photos;
    }
    console.log(nonFormData);
    setModifiedNonFormData(nonFormData);
  };

  const center = {
    lat: 56.54655432928713,
    lng: -115.28172212741737
  }
  
  let wayptMarker = [];
  if (business1.length > 0) {
    business1.map((item, index, arr) => {
      wayptMarker.push(item);
    });
  }

  let markers =
    business1 !== undefined &&
    business1 &&
    wayptMarker.map((item, index) => ({
      id: index + 1,
      name: item.saferPointName,
      position: {
        lat: Number(item && item.order_lat, 10),
        lng: Number(item && item.order_lng, 10)
      }
    }));



  const addSaferPoint = item => {
    var newData = { ...modifiedNonFormData }
    item.saferPointName = "SaferPoint";
    wayptMarker = [];
    wayptMarker.push(item);

    markers = business1 !== undefined &&
      business1 &&
      wayptMarker.map((item, index) => ({
        id: index + 1,
        name: item.saferPointName,
        position: {
          lat: Number(item && item.order_lat, 10),
          lng: Number(item && item.order_lng, 10)
        }
      }));
    setMarkers11(markers)
    newData.saferPointName = markers[0].name
    newData.businessLat = markers[0].position.lat
    newData.businessLng = markers[0].position.lng
    setModifiedNonFormData(newData)
  }
  const handleActiveMarker = marker => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  // const handleOnLoad = map => {
  //   const bounds = new window.google.maps.LatLngBounds();
  //   markers11 && markers11.forEach(({ position }) => bounds.extend(position));
  //   map.fitBounds(bounds);
  // };

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
      markers11 && markers11.forEach(({ position }) => bounds.extend(position));
    map.fitBounds(bounds);
    map.setZoom(10)
    setMap(map)
  }, [])
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])
  const saveChanges = async (modifiedData, setSubmitting) => {
    const response = await fetch(baseAPIURL + "business/" + bid, {
      method: "PUT", // *GET, POST, PUT, DELETE, etc.
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...modifiedData, ...modifiedNonFormData }), // body data type must match "Content-Type" header
    })
      .then((resp) => resp.json())
      .then((res) => res);
    console.log("my res is", response);
    if (response && response.success == true) {
      fetch(baseAPIURL + "notification/pushBusinessOne", {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...modifiedData, ...modifiedNonFormData }), // body data type must match "Content-Type" header
      })
    }
    setSubmitting(false);
    window.location.href = "/admin/business";
  };

  const onDateChange = (toDate, fieldName) => {
    var newData = { ...modifiedNonFormData };
    newData[fieldName] = toDate;
    setModifiedNonFormData(newData);
  };

  const onLocationChange = (addressObject, fieldName) => {
    var newData = { ...modifiedNonFormData };
    const location = {
      longitude: addressObject.location.lng,
      latitude: addressObject.location.lat,
      address: addressObject.label,
      placeID: addressObject.placeId,
      detailedAddress: addressObject.gmaps.address_components,
    };
    newData[fieldName] = location;
    setModifiedNonFormData(newData);
  };

  const handleImageUpload = (event, fieldName, isMultiple) => {
    setIsLoading(true);
    const files = event.target.files;
    const formData = new FormData();
    for (var i = 0; i < files.length; ++i) {
      formData.append("photos", files[i]);
    }

    fetch(baseAPIURL + "businessPicUpload", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        var newData = { ...modifiedNonFormData };
        if (!isMultiple) {
          const url = response.data && response.data[0] && response.data[0].url;
          newData[fieldName] = url;
        } else {
          // multiple photos
          const urls = response.data && response.data.map((item) => item.url);
          if (
            !modifiedNonFormData[fieldName] ||
            modifiedNonFormData[fieldName].length <= 0
          ) {
            newData[fieldName] = urls;
          } else {
            newData[fieldName] = [...modifiedNonFormData[fieldName], ...urls];
          }
        }
        setModifiedNonFormData(newData);
        setIsLoading(false);
        console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleDeletePhoto = (srcToBeRemoved, fieldName, isMultiple) => {
    if (isMultiple) {
      var newData = { ...modifiedNonFormData };
      var currentURLs = newData[fieldName];
      if (currentURLs) {
        const newURLs = currentURLs.filter((src) => src != srcToBeRemoved);
        newData[fieldName] = newURLs;
        setModifiedNonFormData(newData);
      }
    } else {
      var newData = { ...modifiedNonFormData };
      newData[fieldName] = null;
      setModifiedNonFormData(newData);
    }
  };
  if (isLoading) {
    return (
      <div className="sweet-loading card">
        <div className="spinner-container">
          <ClipLoader
            className="spinner"
            sizeUnit={"px"}
            size={50}
            color={"#123abc"}
            loading={isLoading}
          />
        </div>
      </div>
    );
  }
  return (
    <Card className="Card FormCard">
      <CardBody>
        <h1 className='MainPanelHeading'>
          {originalData && originalData.businessName}{" "}
        </h1>

        <Formik
          initialValues={originalData}
          validate={(values) => {
            const errors = {};
            if (!values.businessName) {
              errors.businessName = "Required";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            saveChanges(values, setSubmitting);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <div
                className="FormFieldContainer"
                style={{ width: "26%", float: "left" }}
              >
                <label className="FormLabel">Business Name</label>
                <input
                  className="FormTextField"
                  type="businessName"
                  name="businessName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.businessName}
                />
                <div className="ErrorMessage">
                  {errors.businessName && touched.businessName && errors.businessName}
                </div>
              </div>

              <div
                className="FormFieldContainer"
                style={{ width: "26%", float: "left" }}
              >
                <label className="FormLabel">Business Phone</label>
                <input
                  className="FormTextField"
                  type="businessPhone"
                  name="businessPhone"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.businessPhone}
                />
                <div className="ErrorMessage">
                  {errors.businessPhone && touched.businessPhone && errors.businessPhone}
                </div>
              </div>

              <div
                className="FormFieldContainer"
                style={{ width: "26%", float: "left" }}
              >
                <label className="FormLabel">Business Web URL</label>
                <input
                  className="FormTextField"
                  type="businessURL"
                  name="businessURL"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.businessURL}
                />
                <div className="ErrorMessage">
                  {errors.businessURL && touched.businessURL && errors.businessURL}
                </div>
              </div>

              <div
                className="FormFieldContainer"
                style={{ width: "26%", float: "left" }}
              >
                <label className="FormLabel">City</label>
                <input
                  className="FormTextField"
                  type="city"
                  name="city"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.city}
                />
                <div className="ErrorMessage">
                  {errors.city && touched.city && errors.city}
                </div>
              </div>

              <div
                className="FormFieldContainer"
                style={{ width: "26%", float: "left" }}
              >
                <label className="FormLabel">State</label>
                <input
                  className="FormTextField"
                  type="state"
                  name="state"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.state}
                />
                <div className="ErrorMessage">
                  {errors.state && touched.state && errors.state}
                </div>
              </div>

              <div
                className="FormFieldContainer"
                style={{ width: "26%", float: "left" }}
              >
                <label className="FormLabel">Address</label>
                <input
                  className="FormTextField"
                  type="address"
                  name="address"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address}
                />
                <div className="ErrorMessage">
                  {errors.address && touched.address && errors.address}
                </div>
              </div>

              <div
                className="FormFieldContainer"
                style={{ width: "26%", float: "left" }}
              >
                <label className="FormLabel">Manager's Name</label>
                <input
                  className="FormTextField"
                  type="managerName"
                  name="managerName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.managerName}
                />
                <div className="ErrorMessage">
                  {errors.managerName && touched.managerName && errors.managerName}
                </div>
              </div>

              <div
                className="FormFieldContainer"
                style={{ width: "26%", float: "left" }}
              >
                <label className="FormLabel">Manager's Email</label>
                <input
                  className="FormTextField"
                  type="managerEmail"
                  name="managerEmail"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.managerEmail}
                />
                <div className="ErrorMessage">
                  {errors.managerEmail && touched.managerEmail && errors.managerEmail}
                </div>
              </div>

              <div className="FormFieldContainer" style={{ width: "30%", float: "left" }}>
                <label className="FormLabel">Business Profile Image</label>
                {modifiedNonFormData.profilePictureURL && (
                  <IMPhoto openable dismissable className="photo" src={modifiedNonFormData.profilePictureURL} onDelete={(src) => handleDeletePhoto(src, "profilePictureURL", false)} />
                )}
                <input className="FormFileField" id="profilePictureURL" name="profilePictureURL" type="file" onChange={(event) => {
                  handleImageUpload(event, "profilePictureURL", false);
                }} />
              </div>

              {/* google map start */}
              <GoogleMap 
              center={center}
                onLoad={onLoad}
                onUnmount={onUnmount}
                // zoom={1000}
                options = {{
                  minZoom: 1,
                  maxZoom: 8,
                }}
                onClick={cord => {
                  addSaferPoint({ order_lat: cord.latLng.lat(), order_lng: cord.latLng.lng() })
                }
                }
                mapContainerStyle={{ width: "100%", height: "60vh" }}
              >
                {markers11 &&
                  markers11.map(({ id, name, position }) => (
                    <Marker
                      key={id}
                      position={position}
                      onClick={() => handleActiveMarker(id)}
                      label={{ text: `${id}`, color: "white" }}
                    >
                      {activeMarker === id ? (
                        <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                          <div>{name}</div>
                        </InfoWindow>
                      ) : null}
                    </Marker>
                  ))}
              </GoogleMap>
              {/* google map end */}

              <div className="FormActionContainer" style={{ width: "30%", float: "left" }}>
                <button
                  className="PrimaryButton"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save
                </button>
              </div>
            </form>
          )}
        </Formik>
      </CardBody>
    </Card>
  );
};

export default UpdateBusinessView;
