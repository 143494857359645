import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { ClipLoader } from 'react-spinners';
import { Card, CardBody } from "reactstrap";
import { formatDate } from '../../helpers'

import "../../ui/styles.css"

const baseAPIURL = require('../../config').baseAPIURL;

const DetailedUserView = (props) => {
	let { uid } = useParams()

	const [isLoading, setIsLoading] = useState(true);
	const [originalData, setOriginalData] = useState(null)

	useEffect(() => {
		fetch(baseAPIURL + 'user/' + uid)
		.then(response => response.json())
		.catch(err => {
			console.log(err)
		})
		.then(data => {
			setOriginalData(data)
			setIsLoading(false)
		});
	}, [uid]);

	if (isLoading || !originalData) {
		return (
			<div className="sweet-loading card">
				<div className="spinner-container">
					<ClipLoader
						className="spinner"
						sizeUnit={"px"}
						size={50}
						color={'#123abc'}
						loading={isLoading}
					/>
				</div>
			</div> 
		)
	}

	const editPath = "/admin/user/" + uid + "/update"
	const handleDelete = async (item) => {
        if (window.confirm('Are you sure you want to delete this item?')) {
            const response = await fetch(baseAPIURL + 'user/' + item.id, {
                method: 'DELETE' // *GET, POST, PUT, DELETE, etc.
            });
            window.location.href="/admin/users";
        }
    }

	return (
		<Card className="Card FormCard">
			<CardBody>
			<h1 className='MainPanelHeading'>{originalData && originalData.firstName} {originalData && originalData.lastName}
			</h1>
			<a className="Link EditLink" href={editPath}>Edit</a> |	<a onClick={() => handleDelete(originalData)} className="Link DeleteLink">Delete</a>
			<div className="FormFieldContainer">
				<label className="FormLabel">E-mail</label>
				<span className="LockedFieldValue">{originalData.gmail}</span>
			</div>

			<div className="FormFieldContainer">
				<label className="FormLabel">First Name</label>
				<span className="LockedFieldValue">{originalData.firstName}</span>
			</div>

			<div className="FormFieldContainer">
				<label className="FormLabel">Last Name</label>
				<span className="LockedFieldValue">{originalData.lastName}</span>
			</div>

			<div className="FormFieldContainer">
				<label className="FormLabel">Profile Photo</label>
				{originalData.profilePic && (
					<img className="photo" src={originalData.profilePic} />
				)}
			</div>

			<div className="FormFieldContainer">
				<label className="FormLabel">Creation Date</label>
				<span className="LockedFieldValue">{originalData.createdAt && formatDate(originalData.createdAt)}</span>
			</div>

			<div className="FormFieldContainer">
				<label className="FormLabel">Location</label>
				{originalData.location && originalData.location.address && (
					<span className="LockedFieldValue">{originalData.location.address}</span>
				)}
			</div>

			<div className="FormFieldContainer">
				<label className="FormLabel">Dating Photos</label>
				{originalData.photos && originalData.photos.map((url) =>
					<img className="photo" src={url} />
				)}
			</div>
		</CardBody>
		</Card>
	)
}
    export default DetailedUserView;