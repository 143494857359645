import React from 'react'

import { Route, Switch } from 'react-router-dom'

import Menu from './Menu'
// import { HomePage, NotificationView, EmailView, SubscriptionView, SubscriptionDetailedView, ChatView, UserGesture, EditGesture } from './pages'
import { HomePage, NotificationView, SubscriptionDetailedView, ChatView, UserGesture, EditGesture } from './pages'

import { UsersListView, UpdateUserView, AddNewUserView, DetailedUserView } from '../generated/users';
import { GroupView, AddNewGroupView, UpdateGroupView, DetailedGroupView } from '../generated/groups';
import { AddViewMap, AddBusinessView, BusinessListView, DetailedBusinessView , UpdateBusinessView } from '../generated/business';
import { WalkView } from '../generated/walklist';
import { TemplatesListView, UpdateTemplatesView, AddNewTemplatesView, DetailedTemplatesView } from '../generated/emailTemplates';
// Insert more CRUD imports here
import { SwipesListView, UpdateSwipesView, AddNewSwipesView, DetailedSwipesView } from '../generated/swipes';

import { ReportsListView, UpdateReportsView, AddNewReportsView, DetailedReportsView } from '../generated/reports';



import { LogoutView } from '../../onboarding';
import { ClientUpdateGroupView,ClientDetailedGroupView,GroupMessages } from '../generated/clientGroup';
import ClientGroupLists from '../generated/clientGroup/groupLists';
function AdminView() {
  const userRole = localStorage.getItem("adminRole");
  return (
    <div className="App">
      <div className="MainMenu">
        <Menu />
      </div>
      <div className="MainPanel">
        {userRole == 'clientAdmin'?
         <Switch>
 <Route path="/client/groupsDetails/:gid/update">
            <ClientUpdateGroupView />
          </Route>
          <Route path="/client/groupsList">
         <ClientGroupLists />
         </Route>
         <Route path="/client/groupsDetails/:gid/view">
         <ClientDetailedGroupView />
         </Route>
         <Route path="/client/sendGroupMessage">
         <GroupMessages />
         </Route>
        
         <Route path="/client/islogout">
           <LogoutView />
         </Route>
        
         <Route path="/">
           <div>404 error</div>
         </Route>
       </Switch>
      :
      
        <Switch>
          <Route path="/about">
            <div>About</div>
          </Route>
          <Route path="/admin/sendNotification">
            <NotificationView />
          </Route>
          <Route path="/admin/groups">
            <GroupView />
          </Route>
          <Route path="/admin/group/add">
            <AddNewGroupView />
          </Route>
          <Route path="/admin/group/:gid/update">
            <UpdateGroupView />
          </Route>
          <Route path="/admin/group/:gid/view">
            <DetailedGroupView />
          </Route>
          <Route path="/admin/business/view">
            <AddViewMap />
          </Route>
          <Route path="/admin/business/:bid/view">
            <DetailedBusinessView />
          </Route>
          <Route path="/admin/business/:bid/update">
            <UpdateBusinessView />
          </Route>
          <Route path="/admin/business/add">
            <AddBusinessView />
          </Route>
          <Route path="/admin/business">
            <BusinessListView />
          </Route>
          
          <Route path="/admin/walks">
            <WalkView />
          </Route>
          {/* <Route path="/admin/sendEmail">
            <EmailView />
          </Route> */}
          {/* <Route path="/admin/subscriptions">
            <SubscriptionView />
          </Route> */}
          <Route path="/admin/subscription/:subscriptionId/view">
            <SubscriptionDetailedView />
          </Route>
          <Route path="/admin/users">
            <UsersListView />
          </Route>
          <Route path="/admin/user/:uid/update">
            <UpdateUserView />
          </Route>
          <Route path="/admin/user/:uid/view">
            <DetailedUserView />
          </Route>
          <Route path="/admin/user/add">
            <AddNewUserView />
          </Route>
          <Route path="/admin/chat/:userId">
            <ChatView />
          </Route>
          {/* <Route path="/admin/templates">
            <TemplatesListView />
          </Route>
          <Route path="/admin/template/:templateId/update">
            <UpdateTemplatesView />
          </Route>
          <Route path="/admin/template/:templateId/view">
            <DetailedTemplatesView />
          </Route>
          <Route path="/admin/template/add" component={(props) => <AddNewTemplatesView  {...props} />} /> */}
          {/* Insert more CRUD routes here */}

          {/* <Route path="/admin/swipes">
            <SwipesListView />
          </Route> */}
          {/* <Route path="/admin/swipe/:swipeId/update">
            <UpdateSwipesView />
          </Route> */}
          {/* <Route path="/admin/swipe/:swipeId/view">
            <DetailedSwipesView />
          </Route> */}
          {/* <Route path="/admin/swipe/add">
            <AddNewSwipesView />
          </Route> */}
          {/* <Route path='/admin/usergesture'>
            <UserGesture />
          </Route> */}
          {/* <Route path='/admin/editgesture/:gestureId/update'>
            <EditGesture />
          </Route> */}

          <Route path="/admin/reports">
            <ReportsListView />
          </Route>
          {/* <Route path="/admin/report/:reportId/update">
            <UpdateReportsView />
          </Route>
          <Route path="/admin/report/:reportId/view">
            <DetailedReportsView />
          </Route>
          <Route path="/admin/report/add">
            <AddNewReportsView />
          </Route> */}



          <Route
            path="/firebase/collection/:collectionId"
            render={(props) => (
              <div>Collections</div>)
            }
          />
          <Route path="/page/:pageid" />
          <Route path="/admin/logout">
            <LogoutView />
          </Route>
          <Route path="/admin">
            <HomePage />
          </Route>
          <Route path="/">
            <div>This is the first page a logged in user can see</div>
          </Route>
        </Switch>
      }
      </div>
    </div>
  );
}

export default AdminView;