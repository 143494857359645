import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { Card, CardBody } from "reactstrap";
import { formatDate } from "../../helpers";
import "../../ui/styles.css";
import GroupMessages from "./groupMessages";

const baseAPIURL = require("../../config").baseAPIURL;

const ClientDetailedGroupView = (props) => {
  let { gid } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [originalData, setOriginalData] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(baseAPIURL + "getGroupMembersList/" + gid)
      .then((response) => response.json())
      .then((data) => {
        const groups = data.group_members;
        setData(groups);
        // setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [gid]);

  useEffect(() => {
    fetch(baseAPIURL + "group/" + gid)
      .then((response) => response.json())
      .catch((err) => {
        console.log(err);
      })
      .then((data) => {
        setOriginalData(data);
        setIsLoading(false);
      });
  }, [gid]);

  if (isLoading || !originalData) {
    return (
      <div className="sweet-loading card">
        <div className="spinner-container">
          <ClipLoader
            className="spinner"
            sizeUnit={"px"}
            size={50}
            color={"#123abc"}
            loading={isLoading}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <Card className="Card FormCard">
        <CardBody>
          <h1 className="MainPanelHeading">
            {originalData && originalData.groupName}
          </h1>
          <div className="CardHeader">
            <h4 style={{ fontSize: "18px" }} className="MainPanelHeading">
              Total joined group members : {data.length}{" "}
            </h4>
          </div>
          <div className="FormFieldContainer">
            <label className="FormLabel">Group Name</label>
            <span className="LockedFieldValue">{originalData.groupName}</span>
          </div>
          <div className="FormFieldContainer">
            <label className="FormLabel">Access</label>
            <span className="LockedFieldValue">{originalData.access}</span>
          </div>
          <div className="FormFieldContainer">
            <label className="FormLabel">Group Admin Name</label>
            <span className="LockedFieldValue">
              {originalData.groupAdminName}
            </span>
          </div>
          <div className="FormFieldContainer">
            <label className="FormLabel">Group Admin Email</label>
            <span className="LockedFieldValue">
              {originalData.groupAdminEmail}
            </span>
          </div>
          <div className="FormFieldContainer">
            <label className="FormLabel">Group Admin Phone</label>
            <span className="LockedFieldValue">
              {originalData.groupAdminPhone}
            </span>
          </div>
          <div className="FormFieldContainer">
            <label className="FormLabel">Group Code</label>
            <span className="LockedFieldValue">{originalData.groupCode}</span>
          </div>
          <div className="FormFieldContainer">
            <label className="FormLabel">Profile Photo</label>
            {originalData.profilePictureURL && (
              <img className="photo" src={originalData.profilePictureURL} />
            )}
          </div>
          <div className="FormFieldContainer">
            <label className="FormLabel">Creation Date</label>
            <span className="LockedFieldValue">
              {originalData.createdAt && formatDate(originalData.createdAt)}
            </span>
          </div>
        </CardBody>
      </Card>
      <GroupMessages gid={gid} gMemberlength={data.length} />
    </>
  );
};
export default ClientDetailedGroupView;
