import React, { useEffect, useState,useMemo } from 'react';
import { useParams } from "react-router-dom";
import { ClipLoader } from 'react-spinners';
import { Card, CardBody } from "reactstrap";
import { formatDate } from '../../helpers';
import { IMLocationTableCell, IMImagesTableCell, IMDateTableCell } from '../../ui/IMTable';
import { useHistory } from "react-router-dom";
import {
    Row,
    Col
} from "reactstrap";
import axios from "axios";

import { useTable, usePagination } from "react-table";

import "../../ui/styles.css"
import { getToken } from '../../../onboarding';

const baseAPIURL = require('../../config').baseAPIURL;

const DetailedGroupView = (props) => {
	let { gid } = useParams()

	const [isLoading, setIsLoading] = useState(true);
	const [originalData, setOriginalData] = useState(null)
	const [loginPassword, setLoginPassword] = useState('')

	useEffect(() => {
		fetch(baseAPIURL + 'group/' + gid)
		.then(response => response.json())
		.catch(err => {
			console.log(err)
		})
		.then(data => {
			setOriginalData(data)
			getDetails(data.groupAdminEmail)
		});
	}, [gid]);

	
	const getDetails =(email)=>{
		const token = getToken();
		var emaildata = { email: email };
	
		var config = {
		  method: "post",
		  url: baseAPIURL + "getDetailsByEmail",
		  headers: {
			"Content-Type": "application/json",
			Authorization: token,
		  },
		  data: emaildata,
		};
	
		axios(config)
		  .then(async (response) => {
			setLoginPassword(response.data.groupsData.password);
			setIsLoading(false)
		  })
		  .catch(function (error) {
			console.log(error);
			setIsLoading(false)
		  });
	}
//  new with rajesh
const groupsColumns = [
    {
        Header: "User Name",
        accessor: "fullName",
    },
    {
        Header: "Mobile",
        accessor: "phone",
    },
    {
        Header: "VERIFIED",
        accessor: "verifyProfile",
    },
    {
        Header: "PROFILE PICTURE",
        accessor:  data => {
            return(
            <IMImagesTableCell singleImageURL={data.profilePic} />
        )}
    },
    {
        Header: "Actions",
        accessor: "actions",
        Cell: data => (
            <ActionsItemView data={data} />
        )
    }
]
function ActionsItemView(props) {
	const { data } = props
	// console.log('hello with digiprima technology indore',data.row);
    const history = useHistory();

    const handleView = (item) => {
        const viewPath = "/admin/group/" + data.row.original.id + '/view'
        history.push(viewPath)
    }

    const handleDelete = async (item) => {
		
        if (window.confirm('Are you sure you want to delete this item?',item)) {
			console.log('hello this item with rajesh',item, 'with groupdid ',gid);
			
            const response = await fetch(baseAPIURL + 'deleteMemberToGroup/'+gid+'/'+ item, {
                method: 'GET' // *GET, POST, PUT, DELETE, etc.
            });
			// console.log('retur connset with ',response);
            window.location.reload(false);
        }
    }

    return (
        <div className="inline-actions-container">
            <button onClick={() => handleDelete(data.row.original.uid)} type="button" id="tooltip476609793" className="btn-icon btn btn-danger btn-sm"><i className="fa fa-times"></i></button>
        </div>
    )
}

// const listName = "groups"
const [controlledPageCount, setControlledPageCount] = useState(0)
const [groups, setUsers] = useState([]);
const [data, setData] = useState([]);
const history = useHistory();

// // ss
const columns = useMemo(
	() => groupsColumns,
	[]
)
const {
	getTableProps,
	getTableBodyProps,
	headerGroups,
	prepareRow,
	//pagination
	page,
	canPreviousPage,
	canNextPage,
	pageOptions,
	pageCount,
	gotoPage,
	nextPage,
	previousPage,
	setPageSize,
	// Get the state from the instance
	state: { pageIndex, pageSize },
} = useTable(
	{
		columns,
		data: groups,
		initialState: { pageIndex: 0 },
		manualPagination: true,
		pageCount: controlledPageCount,
	}, usePagination
)

useEffect(() => {
	fetch(baseAPIURL + 'getGroupMembersList/' + gid)
	.then(response => response.json())
		.then(data => {
			const groups = data.group_members;
			setData(groups);
			// setIsLoading(false)
		})
		.catch(err => { console.log(err) });
}, [gid]);

useEffect(() => {
	const startRow = pageSize * pageIndex
	const endRow = startRow + pageSize

	setUsers(data.slice(startRow, endRow))
	setControlledPageCount(Math.ceil(data.length / pageSize))

}, [pageIndex, pageSize, data])

// end with new rajesh
	
	if (isLoading || !originalData) {
		return (
			<div className="sweet-loading card">
				<div className="spinner-container">
					<ClipLoader
						className="spinner"
						sizeUnit={"px"}
						size={50}
						color={'#123abc'}
						loading={isLoading}
					/>
				</div>
			</div> 
		)
	}
	// const editPath = "/admin/group/" + gid + "/update"
	const handleDeleteGroup = async (item) => {
        if (window.confirm('Are you sure you want to delete this item?')) {
            const response = await fetch(baseAPIURL + 'group/' + item.id, {
                method: 'DELETE' // *GET, POST, PUT, DELETE, etc.
            });
            window.location.href="/admin/groups"
        }
    }
	const handleEdit = (item) => {
		const editPath = "/admin/group/" + item.id + '/update'
		history.push(editPath)
	}
	return (
		<>
		<Card className="Card FormCard">
			<CardBody>
			<h1 className='MainPanelHeading'>{originalData && originalData.groupName}
			</h1>
				{/* <a onClick={() => handleDeleteGroup(originalData)} className="Link DeleteLink">Delete</a>
				<a onClick={() => handleEdit(originalData)} className="Link DeleteLink">Edit</a> */}
			<a onClick={() => handleEdit(originalData)} style = {{cursor:"pointer"}} className="Link EditLink" >Edit</a> |	<a onClick={() => handleDeleteGroup(originalData)} className="Link DeleteLink">Delete</a>				
			<div className="FormFieldContainer">
				<label className="FormLabel">Group Name</label>
				<span className="LockedFieldValue">{originalData.groupName}</span>
			</div>

			<div className="FormFieldContainer">
				<label className="FormLabel">Access</label>
				<span className="LockedFieldValue">{originalData.access}</span>
			</div>

			<div className="FormFieldContainer">
				<label className="FormLabel">Group Admin Name</label>
				<span className="LockedFieldValue">{originalData.groupAdminName}</span>
			</div>

			<div className="FormFieldContainer">
				<label className="FormLabel">Group Admin Email</label>
				<span className="LockedFieldValue">{originalData.groupAdminEmail}</span>
			</div>

			<div className="FormFieldContainer">
				<label className="FormLabel">Group Admin Phone</label>
				<span className="LockedFieldValue">{originalData.groupAdminPhone}</span>
			</div>

			<div className="FormFieldContainer">
				<label className="FormLabel">Group Code</label>
				<span className="LockedFieldValue">{originalData.groupCode}</span>
			</div>

			<div className="FormFieldContainer">
				<label className="FormLabel">Profile Photo</label>
				{originalData.profilePictureURL && (
					<img className="photo" src={originalData.profilePictureURL} />
				)}
			</div>
			<div className="FormFieldContainer">
				<label className="FormLabel">Creation Date</label>
				<span className="LockedFieldValue">{originalData.createdAt && formatDate(originalData.createdAt)}</span>
			</div>
			{loginPassword ? 	<div className="FormFieldContainer">
				<label className="FormLabel">Access Password</label>
				<span className="LockedFieldValue">{loginPassword}</span>
			</div> : null}
		</CardBody>
		</Card>
	
	<Row>
		<Col md="12">
			<div className="Card">
				<div className="CardHeader">
					<h4 style={{fontSize: "18px"}}className='MainPanelHeading'>Total joined group members : {page.length} </h4>
				</div>
				<div className="CardBody">
					<div className="TableContainer">
						<table className="Table" {...getTableProps()}>
							<thead>
								{headerGroups.map(headerGroup => (
									<tr {...headerGroup.getHeaderGroupProps()}>
										{headerGroup.headers.map(column => (
											<th {...column.getHeaderProps()}>{column.render('Header')}</th>
										))}
									</tr>
								))}
							</thead>
							<tbody {...getTableBodyProps()}>
								{page.map((row, i) => {
									prepareRow(row)
									return (
										<tr {...row.getRowProps()}>
											{row.cells.map(cell => {
												return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
											})}
										</tr>
									)
								})}
								<tr>
									{isLoading ? (
										<td colSpan={groupsColumns.length - 1}>
											<p>
												Loading...
											</p>
										</td>
									) : (
											<td colSpan={groupsColumns.length - 1}>
												<p className="PaginationDetails">
													Showing {page.length} of {data.length}{' '} results
												</p>
											</td>
										)}
								</tr>
							</tbody>
						</table>
						<div className="Pagination">
							<div className="LeftPaginationButtons">
								<button onClick={() => gotoPage(0)} className="PaginationButton" disabled={!canPreviousPage}>
									<i className="fa fa-angle-double-left"></i>
								</button>{' '}
								<button onClick={() => previousPage()} className="PaginationButton" disabled={!canPreviousPage}>
									<i className="fa fa-angle-left"></i>
								</button>
							</div>
							<div className="CenterPaginationButtons">
								<span>
									Page{' '}
									<strong>
										{pageIndex + 1} of {pageOptions.length}
									</strong>{' '}
								</span>
								<span>
									| Go to page:{' '}
									<input
										type="number"
										defaultValue={pageIndex + 1}
										onChange={e => {
											const page = e.target.value ? Number(e.target.value) - 1 : 0
											gotoPage(page)
										}}
										style={{ width: '100px' }}
									/>
								</span>{' '}
								<select
									value={pageSize}
									onChange={e => {
										setPageSize(Number(e.target.value))
									}}
								>
									{[10, 20, 30, 40, 50].map(pageSize => (
										<option key={pageSize} value={pageSize}>
											Show {pageSize}
										</option>
									))}
								</select>
							</div>
							<div className="RightPaginationButtons">
								<button onClick={() => nextPage()} className="PaginationButton" disabled={!canNextPage}>
									<i className="fa fa-angle-right"></i>
								</button>{' '}
								<button onClick={() => gotoPage(pageCount - 1)} className="PaginationButton" disabled={!canNextPage}>
									<i className="fa fa-angle-double-right"></i>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Col>
	</Row>
	</>
	)

}
    export default DetailedGroupView;