exports.baseAPIURL = 'https://apis.asaferwalk.com/api/';

exports.rootURL = 'https://apis.asaferwalk.com/';

// exports.baseAPIURL = 'http://localhost:3000/api/';

// exports.rootURL = 'http://localhost:3000/';

exports.secretOrKey = "hGXoNRl8ukug48pCfY2p";

exports.subscriptionProducts = {
    'com.instaswipey.FreeTrial.InstaswipeyAutoRenewableSubscriptionByMonth': {
        price: 5,
        period: 'month',
    },
    'com.instaswipey.FreeTrial.InstaswipeyAutoRenewableSubscriptionByYear': {
        price: 45,
        period: 'year',
    }
};
