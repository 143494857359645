import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { ClipLoader } from 'react-spinners';
import IMDatePicker from '../../ui/IMDatePicker';
import { IMPhoto } from '../../../common';
import { Card, CardBody } from "reactstrap";
import {
    GoogleMap,
    InfoWindow,
    Marker
} from "@react-google-maps/api";

import { getToken } from "../../../onboarding";

import "../../ui/styles.css"
import axios from 'axios';

const baseAPIURL = require('../../config').baseAPIURL;

const AddBusinessView = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [modifiedNonFormData, setModifiedNonFormData] = useState({})

    const [activeMarker, setActiveMarker] = useState(null);
    const [markers1, setMarkers] = useState([]);

    useEffect(() => {
        setModifiedNonFormData({ createdAt: new Date() });
        // let aa={order_lat:'-0.44257670333463695',order_lng:'177.39501781761646'}
        // addSafePoint(aa);
    }, []);

    const createBusiness = async (data, setSubmitting) => {
        const getuid = localStorage.getItem('uid')
        data.uid = getuid;
        console.log('daat', data);
        console.log(JSON.stringify(data));
        if (modifiedNonFormData.businessLat == null) {
            alert("Please mark the point on map");
            console.log("markers223", markers1);
        }
        setIsLoading(true);
        const response = await fetch(baseAPIURL + 'business/add', {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...data, ...modifiedNonFormData }) // body data type must match "Content-Type" header
        });
        setSubmitting(false);
        setIsLoading(false);
    }

    const onDateChange = (toDate, fieldName) => {
        var newData = { ...modifiedNonFormData }
        newData[fieldName] = toDate
        setModifiedNonFormData(newData)
    }

    const handleImageUpload = (event, fieldName, isMultiple) => {
        const files = event.target.files
        const formData = new FormData()
        for (var i = 0; i < files.length; ++i) {
            formData.append('photos', files[i])
        }

        fetch(baseAPIURL + 'businessPicUpload', {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(response => {
                var newData = { ...modifiedNonFormData }
                if (!isMultiple) {
                    const url = response.data && response.data[0] && response.data[0].url
                    newData[fieldName] = url
                } else {
                    // multiple photos
                    const urls = response.data && response.data.map(item => item.url)
                    if (!modifiedNonFormData[fieldName] || modifiedNonFormData[fieldName].length <= 0) {
                        newData[fieldName] = urls
                    } else {
                        newData[fieldName] = [...modifiedNonFormData[fieldName], ...urls]
                    }
                }
                setModifiedNonFormData(newData)
                console.log(response)
            })
            .catch(error => {
                console.error(error)
            })
    }

    const handleDeletePhoto = (srcToBeRemoved, fieldName, isMultiple) => {
        if (isMultiple) {
            var newData = { ...modifiedNonFormData }
            var currentURLs = newData[fieldName]
            if (currentURLs) {
                const newURLs = currentURLs.filter(src => src != srcToBeRemoved)
                newData[fieldName] = newURLs
                setModifiedNonFormData(newData)
            }
        } else {
            var newData = { ...modifiedNonFormData }
            newData[fieldName] = null
            setModifiedNonFormData(newData)
        }
    }

    const center = {
        lat: 56.54655432928713,
        lng: -115.28172212741737
    }

    let wayptMarker = [];

    const addSafePoint = item => {
        console.log('wayptMarker',item);
        var newData = { ...modifiedNonFormData }
        item.saferPointName = "SaferPoint";
        wayptMarker.push(item);

        let markers = 
            wayptMarker.map((item, index) => ({
                id: index + 1,
                name: item.saferPointName,
                position: {
                    lat: Number(item && item.order_lat, 10),
                    lng: Number(item && item.order_lng, 10)
                }
            }));
        setMarkers(markers)
        newData.saferPointName = markers[0].name
        newData.businessLat = markers[0].position.lat
        newData.businessLng = markers[0].position.lng
        setModifiedNonFormData(newData)
    }
    const handleActiveMarker = marker => {
        if (marker === activeMarker) {
            return;
        }
        setActiveMarker(marker);
    };

    const handleOnLoad = map => {
        const bounds = new window.google.maps.LatLngBounds();
        markers1 && markers1.forEach(({ position }) => bounds.extend(position));
        map.fitBounds(bounds);
    };

    if (isLoading) {
        return (
            <div className="sweet-loading card">
                <div className="spinner-container">
                    <ClipLoader
                        className="spinner"
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={isLoading}
                    />
                </div>
            </div>
        )
    }

    return (
        <Card className="Card FormCard">
            <CardBody>
                <h1 className='MainPanelHeading'>Create New Safer Spot</h1>
                <Formik
                    initialValues={{}}
                    validate={values => {
                        const errors = {};

                        if (!values.businessName) {
                            errors.businessName = 'Required'
                        }
                        // if (markers1.length == 0) {
                        //     alert("Please mark the point on map");
                        //     console.log("markers22", markers1);
                        // }
                        // if (!values.groupCode) {
                        //     errors.groupCode = 'Required'
                        // }

                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        createBusiness(values, setSubmitting);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                        /* and other goodies */
                    }) => (
                        <form onSubmit={handleSubmit}>

                            <div className="FormFieldContainer">
                                <label className="FormLabel">Business Name</label>
                                <input
                                    className="FormTextField"
                                    type="businessName"
                                    name="businessName"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.businessName}
                                />
                                <div className="ErrorMessage">
                                    {errors.businessName && touched.businessName && errors.businessName}
                                </div>
                            </div>
                            <div className="FormFieldContainer">
                                <label className="FormLabel">Business Phone</label>
                                <input
                                    className="FormTextField"
                                    type="businessPhone"
                                    name="businessPhone"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.businessPhone}
                                />
                                <div className="ErrorMessage">
                                    {errors.businessPhone && touched.businessPhone && errors.businessPhone}
                                </div>
                            </div>
                            <div className="FormFieldContainer">
                                <label className="FormLabel">Business Web URL</label>
                                <input
                                    className="FormTextField"
                                    type="businessURL"
                                    name="businessURL"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.businessURL}
                                />
                                <div className="ErrorMessage">
                                    {errors.businessURL && touched.businessURL && errors.businessURL}
                                </div>
                            </div>
                            <div className="FormFieldContainer">
                                <label className="FormLabel">City</label>
                                <input
                                    className="FormTextField"
                                    type="city"
                                    name="city"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.city}
                                />
                                <div className="ErrorMessage">
                                    {errors.city && touched.city && errors.city}
                                </div>
                            </div>
                            <div className="FormFieldContainer">
                                <label className="FormLabel">State / Prov</label>
                                <input
                                    className="FormTextField"
                                    type="state"
                                    name="state"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.state}
                                />
                                <div className="ErrorMessage">
                                    {errors.state && touched.state && errors.state}
                                </div>
                            </div>
                            <div className="FormFieldContainer">
                                <label className="FormLabel">Address</label>
                                <input
                                    className="FormTextField"
                                    type="address"
                                    name="address"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.address}
                                />
                                <div className="ErrorMessage">
                                    {errors.address && touched.address && errors.address}
                                </div>
                            </div>
                            <div className="FormFieldContainer">
                                <label className="FormLabel">Business Logo</label>
                                {modifiedNonFormData.profilePictureURL && (
                                    <IMPhoto openable dismissable className="photo" src={modifiedNonFormData.profilePictureURL} onDelete={(src) => handleDeletePhoto(src, "profilePictureURL", false)} />
                                )}
                                <input className="FormFileField" id="profilePictureURL" name="profilePictureURL" type="file" onChange={(event) => {
                                    handleImageUpload(event, "profilePictureURL", false);
                                }} />
                            </div>
                            <div className="FormFieldContainer">
                                <label className="FormLabel">Manager's Name</label>
                                <input
                                    className="FormTextField"
                                    type="managerName"
                                    name="managerName"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.managerName}
                                />
                                <div className="ErrorMessage">
                                    {errors.managerName && touched.managerName && errors.managerName}
                                </div>
                            </div>
                            <div className="FormFieldContainer">
                                <label className="FormLabel">Manager's Email</label>
                                <input
                                    className="FormTextField"
                                    type="managerEmail"
                                    name="managerEmail"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.managerEmail}
                                />
                                <div className="ErrorMessage">
                                    {errors.managerEmail && touched.managerEmail && errors.managerEmail}
                                </div>
                            </div>
                            <div className="FormFieldContainer">
                                <label className="FormLabel">Creation Date</label>
                                <IMDatePicker
                                    selected={modifiedNonFormData.createdAt}
                                    onChange={(toDate) => onDateChange(toDate, "createdAt")}
                                />
                            </div>

                            {/* google map start */}
                            <GoogleMap center={center}  zoom={4}
                                onLoad={handleOnLoad}
                                onClick={cord => {
                                    addSafePoint({ order_lat: cord.latLng.lat(), order_lng: cord.latLng.lng() })
                                }
                                }
                                mapContainerStyle={{ width: "100%", height: "60vh" }}
                            >
                                {markers1 &&
                                    markers1.map(({ id, name, position }) => (
                                        <Marker
                                            key={id}
                                            position={position}
                                            onClick={() => handleActiveMarker(id)}
                                            label={{ text: `${id}`, color: "white" }}
                                        >
                                            {activeMarker === id ? (
                                                <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                                                    <div>{name}</div>
                                                </InfoWindow>
                                            ) : null}
                                        </Marker>
                                    ))}
                            </GoogleMap>
                            {/* google map end */}

                            <div className="FormActionContainer">
                                <button className="PrimaryButton" type="submit" disabled={isSubmitting}>
                                    Create Business
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
            </CardBody>
        </Card>
    )
}

export default AddBusinessView;