import firebase from 'firebase/app';
import 'firebase/auth';
// import 'firebase/firestore';

//enter your web app configuration options
// const firebaseConfig = {
//     apiKey: "AIzaSyACXRCXyIfoWQTPxt-04eHIOIJNu6Hcw-o",
//     authDomain: "safer-walk-356a5.firebaseapp.com",
//     projectId: "safer-walk-356a5",
//     databaseURL: "https://safer-walk-356a5-default-rtdb.firebaseio.com",
//     storageBucket: "gs://safer-walk-356a5.appspot.com",
//     messagingSenderId: "131851742104",
//     appId: "1:131851742104:web:da781ed7e83aaf8bcf59ed",
//     measurementId: "G-9XXRK0162G"
//   };
const firebaseConfig = {
  apiKey: "AIzaSyAIaYdDWsA5CN1OLTD6Lq0dtzB6Bg6Rucg",
  authDomain: "a-safer-walk.firebaseapp.com",
  projectId: "a-safer-walk",
  storageBucket: "gs://a-safer-walk.appspot.com",
  messagingSenderId: "774549660917",
  appId: "1:774549660917:web:a14cfc1616675611fe51bc",
  measurementId: "G-THMLRQBSK2"
};

if (!firebase.apps.length) firebase.initializeApp(firebaseConfig);

export { firebase };