import React, { useState } from "react";
import { Row, Col, Form, FormGroup, Input, Button } from "reactstrap";
import axios from "axios";
import "./style.css";

const baseAPIURL = require("../../config").baseAPIURL;

function GroupMessages(props) {
  const localGID = localStorage.getItem("groupId") || props.gid;
  console.log(localGID,"wwwwwwwwwwwwwwwwwwwwwwwwwwwwwww",);
  const [isLoading, setIsLoading] = useState(false);
  const [isType, setIsType] = useState("");
  const [isVal, setIsVal] = useState("");
  const [fileName, setFileName] = useState("");
  const [appImg, setAppImg] = useState("");
  const [emailImg, setEmailImg] = useState("");

  const [inputTxt, setInputTxt] = useState({
    sms: "",
    email: "",
    email_subject: "",
    mobile_app: "",
    mobile_app_url: "",
  });

  const handleTextInput = (event, type) => {
    const { name, value } = event.target;
    setInputTxt((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const sendSms = (type) => {
    if (props.gMemberlength == 0) {
      return alert("This group not any member");
    }
    setIsType(type);
    if (type == "sms" && inputTxt.sms == 0) {
      return alert("Please enter message.");
    }

    if (
      type == "mobile_app" &&
      inputTxt.mobile_app.length == 0 &&
      appImg == "" &&
      inputTxt.mobile_app_url == ""
    ) {
      return alert("Please fill any field.");
    }

    if (type == "email" && inputTxt.email_subject == "") {
      return alert("Please fill any field.");
    }

    var data = {
      gid: localGID,
      types: type,
      sms: inputTxt.sms,
      mobile_app: inputTxt.mobile_app,
      mobile_app_url: inputTxt.mobile_app_url,
      mobile_app_image: appImg,
      email: inputTxt.email,
      email_subject: inputTxt.email_subject,
      email_image: emailImg,
    };
    console.log("datadatadata", data);

    setIsLoading(true);
    setIsVal("Sending...");

    var config = {
      method: "post",
      url: baseAPIURL + "sendSmsGroupAllUser",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log("responseresponse",response);
        setInputTxt({
          sms: "",
          email: "",
          email_subject: "",
          mobile_app: "",
          mobile_app_url: "",
        });
        setIsLoading(false);
        setIsVal("");
        setFileName("");
        setAppImg("");
        setEmailImg("");
        if (response.data.success == false) {
          alert("not send message");
        }else{

          alert("send successfull");
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
        setIsVal("");
        setFileName("");
        setAppImg("");
        setEmailImg("");
      });
  };

  const handleImageUpload = (event, fieldName) => {
    console.log("my image type", event);
    setIsType(fieldName);
    // return;
    setIsVal("Please Wait...");
    setIsLoading(true);
    setFileName(event.target.files[0].name);
    const files = event.target.files;
    const formData = new FormData();
    for (var i = 0; i < files.length; ++i) {
      formData.append("photos", files[i]);
    }

    fetch(baseAPIURL + "uploadGrpPic", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status == true) {
          setAppImg(response.data[0].url);
          if (fieldName == "email") {
            setEmailImg(response.data[0].url);
            setAppImg("");
          } else if (fieldName == "mobile_app") {
            setEmailImg("");
            setAppImg(response.data[0].url);
          } else {
          }
          setIsVal("");
          setIsLoading(false);
        } else {
          setIsVal("");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <div className="Card">
              <Form>
                <div className="CardHeader">
                  <h1 className="MainPanelHeading">
                    Group Mass Messaging System
                  </h1>
                </div>
                <FormGroup>
                  <h4>In-App Chat</h4>
                  <Input
                    id="exampleText"
                    name="mobile_app"
                    value={inputTxt.mobile_app}
                    onChange={(e) => handleTextInput(e, "mobile_app")}
                    type="textarea"
                    rows="10"
                    cols="100"
                    className="Textarea_class"
                  />
                  <div className="Buttons">
                    <div className="Files_upload">
                      <label className="Button_class" htmlFor="fileInput">
                        Image
                      </label>
                      <input
                        id="fileInput"
                        type="file"
                        onChange={(e) => {
                          handleImageUpload(e, "mobile_app");
                        }}
                        hidden
                        disabled={isLoading}
                      />
                      <label>
                        {fileName && isType == "mobile_app" ? fileName : ""}
                      </label>
                    </div>

                    <i className="fa fa-times fa-lg " aria-hidden="true"></i>

                    <Button
                      size="lg"
                      disabled={
                        isLoading && isType == "mobile_app"
                          ? true
                          : isLoading && fileName && isType == "mobile_app"
                          ? true
                          : isLoading
                          ? true
                          : false
                      }
                      className="Send_button"
                      onClick={() => {
                        if (isType != "email") {
                          setIsVal("");
                          setEmailImg("");
                          setFileName("");
                        }
                        const fileInput1 =
                          document.getElementById("fileInput1");
                        fileInput1.value = "";
                        sendSms("mobile_app");
                      }}
                    >
                      {isLoading && isType == "mobile_app"
                        ? isVal
                        : isLoading && fileName && isType == "mobile_app"
                        ? isVal
                        : "Send"}
                    </Button>
                  </div>
                  <div className="Second_buttons">
                    <div className="Link_files">
                      <Button
                        className="Button_class"
                        size="lg"
                        disabled={true}
                      >
                        Link
                      </Button>
                      <Input
                        name="mobile_app_url"
                        type="textarea"
                        value={inputTxt.mobile_app_url}
                        className="Textarea_class1"
                        onChange={(e) => handleTextInput(e, "mobile_app_url")}
                      />
                    </div>
                    <i className="fa fa-times fa-lg " aria-hidden="true"></i>
                  </div>
                </FormGroup>

                <FormGroup>
                  <h4>Mass Email Message</h4>
                  <Input
                    name="email_subject"
                    type="textarea"
                    placeholder="Subject Line"
                    className="Textarea_class1"
                    value={inputTxt.email_subject}
                    onChange={(e) => handleTextInput(e, "email_subject")}
                  />
                  <Input
                    name="email"
                    type="textarea"
                    rows="10"
                    cols="100"
                    className="Textarea_class"
                    value={inputTxt.email}
                    onChange={(e) => handleTextInput(e, "email")}
                  />

                  <div className="Buttons">
                    <div className="Files_upload">
                      <label className="Button_class" htmlFor="fileInput1">
                        Image
                      </label>
                      <input
                        id="fileInput1"
                        type="file"
                        onChange={(e) => {
                          handleImageUpload(e, "email");
                        }}
                        hidden
                        disabled={isLoading}
                      />
                      <label>
                        {fileName && isType == "email" ? fileName : ""}
                      </label>
                    </div>

                    <i className="fa fa-times fa-lg " aria-hidden="true"></i>
                    <Button
                      size="lg"
                      disabled={
                        isLoading && isType == "email"
                          ? true
                          : isLoading && fileName && isType == "email"
                          ? true
                          : isLoading
                          ? true
                          : false
                      }
                      className="Send_button"
                      onClick={() => {
                        if (isType != "email") {
                          setIsVal("");
                          setAppImg("");
                          setFileName("");
                        }
                        const fileInput = document.getElementById("fileInput");
                        fileInput.value = "";
                        sendSms("email");
                      }}
                    >
                      {isLoading && isType == "email"
                        ? isVal
                        : isLoading && fileName && isType == "email"
                        ? isVal
                        : "Send"}
                    </Button>
                  </div>
                </FormGroup>
                <FormGroup>
                  <h4>Mass Mobile Text Message - Major Alerts + Emergencies</h4>
                  <h3 className="message">
                    Sending mass alerts may incur text charges to your
                    subscription.{" "}
                  </h3>

                  <Input
                    id="exampleText"
                    name="sms"
                    type="textarea"
                    rows="10"
                    cols="100"
                    className="Textarea_class"
                    value={inputTxt.sms}
                    onChange={(e) => handleTextInput(e, "sms")}
                  />

                  <div className="Send_Buttons">
                    <Button
                      disabled={
                        isLoading && isType == "sms"
                          ? true
                          : isLoading
                          ? true
                          : false
                      }
                      size="lg"
                      className="Send_button"
                      onClick={() => {
                        setAppImg("");
                        setEmailImg("");
                        setIsVal("");
                        setFileName("");
                        const fileInput1 =
                          document.getElementById("fileInput1");
                        fileInput1.value = "";
                        const fileInput = document.getElementById("fileInput");
                        fileInput.value = "";
                        sendSms("sms");
                      }}
                    >
                      {isLoading && isType == "sms" ? isVal : "Send"}
                    </Button>
                  </div>
                </FormGroup>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default GroupMessages;
