import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { ClipLoader } from 'react-spinners';
import { Card, CardBody } from "reactstrap";
import { formatDate } from '../../helpers'

import "../../ui/styles.css"

const baseAPIURL = require('../../config').baseAPIURL;

const DetailedBusinessView = (props) => {
    let { bid } = useParams()
    const [isLoading, setIsLoading] = useState(true);
    const [originalData, setOriginalData] = useState(null)

    useEffect(() => {
        fetch(baseAPIURL + 'business/' + bid)
            .then(response => response.json())
            .catch(err => {
                console.log(err)
            })
            .then(data => {
                setOriginalData(data)
                setIsLoading(false)
            });
    }, [bid]);

    if (isLoading || !originalData) {
        return (
            <div className="sweet-loading card">
                <div className="spinner-container">
                    <ClipLoader
                        className="spinner"
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={isLoading}
                    />
                </div>
            </div>
        )
    }

    const editPath = "/admin/business/" + bid + "/update"
    const handleDelete = async (item) => {
        if (window.confirm('Are you sure you want to delete this item?')) {
            const response = await fetch(baseAPIURL + 'business/' + item.id, {
                method: 'DELETE' // *GET, POST, PUT, DELETE, etc.
            });
            window.location.reload(false);
        }
    }

    return (
        <Card className="Card FormCard">
            <CardBody>
                <h1 className='MainPanelHeading'>{originalData && originalData.businessName}
                </h1>
                <a className="Link EditLink" href={editPath}>Edit</a> |	<a onClick={() => handleDelete(originalData)} className="Link DeleteLink">Delete</a>

                <div className="FormFieldContainer">
                    <label className="FormLabel">Business Name</label>
                    <span className="LockedFieldValue">{originalData.businessName}</span>
                </div>

                <div className="FormFieldContainer">
                    <label className="FormLabel">Business Phone</label>
                    <span className="LockedFieldValue">{originalData.businessPhone}</span>
                </div>

                <div className="FormFieldContainer">
                    <label className="FormLabel">Business Web URL</label>
                    <span className="LockedFieldValue">{originalData.businessURL}</span>
                </div>

                <div className="FormFieldContainer">
                    <label className="FormLabel">Manager's Name</label>
                    <span className="LockedFieldValue">{originalData.managerName}</span>
                </div>

                <div className="FormFieldContainer">
                    <label className="FormLabel">Manager's Email</label>
                    <span className="LockedFieldValue">{originalData.managerEmail}</span>
                </div>

                <div className="FormFieldContainer">
                    <label className="FormLabel">City</label>
                    <span className="LockedFieldValue">{originalData.city}</span>
                </div>

                <div className="FormFieldContainer">
                    <label className="FormLabel">State / Prov</label>
                    <span className="LockedFieldValue">{originalData.state}</span>
                </div>

                <div className="FormFieldContainer">
                    <label className="FormLabel">Address</label>
                    <span className="LockedFieldValue">{originalData.address}</span>
                </div>

                <div className="FormFieldContainer">
                    <label className="FormLabel">Latitude</label>
                    <span className="LockedFieldValue">{originalData.businessLat}</span>
                </div>

                <div className="FormFieldContainer">
                    <label className="FormLabel">Longitude</label>
                    <span className="LockedFieldValue">{originalData.businessLng}</span>
                </div>

                <div className="FormFieldContainer">
                    <label className="FormLabel">Profile Photo</label>
                    {originalData.profilePictureURL && (
                        <img class="photo" src={originalData.profilePictureURL} />
                    )}
                </div>

                <div className="FormFieldContainer">
                    <label className="FormLabel">Creation Date</label>
                    <span className="LockedFieldValue">{originalData.createdAt && formatDate(originalData.createdAt)}</span>
                </div>
            </CardBody>
        </Card>
    )
}
export default DetailedBusinessView;