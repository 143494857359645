import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { ClipLoader } from "react-spinners";
import IMDatePicker from "../../ui/IMDatePicker";
import { IMLocationPicker } from "../../ui/IMLocationPicker";
import { IMPhoto } from "../../../common";
import { Card, CardBody } from "reactstrap";
import { IMToggleSwitchComponent } from "../../../common";

import "../../ui/styles.css";
import axios from "axios";
import { getToken } from "../../../onboarding";

const baseAPIURL = require("../../config").baseAPIURL;
const AddNewGroupView = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [modifiedNonFormData, setModifiedNonFormData] = useState({});

  const [events, setEvents] = useState("");
  const [values, setValues] = useState({});
  const [conditions, setCondition] = useState("");
  const [field, setField] = useState("");
  const [isImageUploadDisabled, setIsImageUploadDisabled] = useState(false);
  const [isExcelUploadDisabled, setIsExcelUploadDisabled] = useState(false);
  const [val, setVal] = useState("Create Group");
  const [isUploaded, setisUploaded] = useState(false);

  useEffect(() => {
    setModifiedNonFormData({ createdAt: new Date() });
  }, []);

  const createGroup = async (data, setSubmitting) => {
    const getuid = localStorage.getItem("uid");
    data.uid = getuid;
    if (data.access == null) {
      data.access = "public";
    }
    console.log("daat", data);
    console.log(JSON.stringify(data));
    setIsLoading(true);

    const loginPassword = `${
      data.groupAdminName.split(" ")[0]
    }@${data.groupAdminPhone.substring(0, 3)}${Math.floor(
      100 + Math.random() * 900
    )}`;
    const regi = {
      email: data.groupAdminEmail,
      groupCode: data.groupCode,
      password: loginPassword,
      role: "clientAdmin",
      groupAdminName: data.groupAdminName,
      groupName: data.groupName,
      firstName: data.groupAdminName,
      lastName: data.groupName,
      mobileNumber: data.groupAdminPhone,
    };
    var config = {
      method: "post",
      url: baseAPIURL + "checkAndRegisterGroup",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: regi,
    };
    axios(config)
      .then(async (response) => {
        console.log("configconfig", response.data);
        const emailAlreadyExist = response.data.emailTypes;
        if (response.data.success == false) {
          setSubmitting(false);
          setIsLoading(false);
          setModifiedNonFormData({
            profilePictureURL: "",
            createdAt: new Date(),
          });
          return alert(response.data.message);
        } else {
          const response = await fetch(baseAPIURL + "group/add", {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ ...data, ...modifiedNonFormData }), // body data type must match "Content-Type" header
          })
            .then((val) => val.json())
            .then(async (data) => {
              if (data.message == "try with other code") {
                alert("try with different group code");
              }
              await handleCSVUpload(
                events,
                field,
                conditions,
                values,
                data.gid,
                data.groupCode,
                data.uid
              );
              if (emailAlreadyExist == "emailAlreadyExist") {
                await alredyemailReg(
                  data.groupAdminEmail,
                  data,
                  loginPassword,
                  setSubmitting,
                  emailAlreadyExist
                );
              } else {
                const emailInfo = {
                  msg: `<p>Congratulations!\nYour group account was successfully created. </p><p>UserId: ${data.groupAdminEmail} </p><p>Password: ${loginPassword}</p> <p>Link: https://panel.asaferwalk.com/</p>`,
                  subject: "You created a group with A Safer Walk",
                  toEmail: data.groupAdminEmail,
                };
               
                await sendSmsCreateGroup(
                  data,
                  loginPassword,
                  setSubmitting,
                  emailAlreadyExist,
                  emailInfo
                );
              }
              // setSubmitting(false);
              // setIsLoading(false);
              // window.location.href = "/admin/groups";
            });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const sendSmsCreateGroup = (val, loginPassword, setSubmitting, isType,emailInfo) => {
    var data;
    if (isType == "emailAlreadyExist") {
      data = {
        toMobileNumber: val.groupAdminPhone,
        userid: val.groupAdminEmail,
        password: loginPassword,
        type: "group_update",
      };
    } else {
      data = {
        toMobileNumber: val.groupAdminPhone,
        userid: val.groupAdminEmail,
        password: loginPassword,
        type: "group_create",
      };
    }

    var config = {
      method: "post",
      url: baseAPIURL + "singleTextMsg",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        sendEmail(emailInfo);
        // setSubmitting(false);
        // setIsLoading(false);
        // window.location.href = "/admin/groups";
        console.log("group_create", JSON.stringify(response.data));
      })
      .catch(function (error) {
        sendEmail(emailInfo);
        console.log("group_createeeeeee", error);
      });
  };

  const alredyemailReg = (
    email,
    data,
    loginPassword,
    setSubmitting,
    emailAlreadyExist
  ) => {
    const token = getToken();
    var emaildata = { email: email };

    var config = {
      method: "post",
      url: baseAPIURL + "getDetailsByEmail",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      data: emaildata,
    };

    axios(config)
      .then(async (response) => {
        const emailInfo = {
          msg: `<p>Congratulations!\nYour group account was successfully created. plz login to check</p><p>UserId: ${email}</p><p>Password: ${response.data.groupsData.password}</p> <p>Link: https://panel.asaferwalk.com/</p>`,
          subject: "You created a group with A Safer Walk",
          toEmail: email,
        };
        await sendSmsCreateGroup(
          data,
          response.data.groupsData.password,
          setSubmitting,
          emailAlreadyExist,
          emailInfo
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const sendEmail = async(val) => {
    var emailData = {
      msg: val.msg,
      subject: val.subject,
      toEmail: [val.toEmail, 'trevor@asaferwalk.com'],
    };

    var config = {
      method: "post",
      url: baseAPIURL + "singleEmailMsg",
      headers: {
        "Content-Type": "application/json",
      },
      data: emailData,
    };

   await axios(config)
      .then(function (response) {
      
        window.location.href = "/admin/groups";
        setIsLoading(false);
        console.log("lllllllllllllllllllllllllllllllllllllllllll",JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const onDateChange = (toDate, fieldName) => {
    var newData = { ...modifiedNonFormData };
    newData[fieldName] = toDate;
    setModifiedNonFormData(newData);
  };

  const imageSubmitClicked = (event) => {
    if (!document.getElementById("profilePictureURL").value) {
      setIsImageUploadDisabled(true);
    } else {
      setIsImageUploadDisabled(false);
    }
  };

  const excelSubmitClicked = (event) => {
    if (modifiedNonFormData.access === "private") {
      if (!document.getElementById("CSVuploadURL").value) {
        setIsExcelUploadDisabled(true);
      } else {
        setIsExcelUploadDisabled(false);
      }
    }
  };
  //   const excelUploadProps = {
  //     // accept: '.xls, .xlsx',
  //     // name: 'excel file name',
  //     // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  //     // headers: {
  //     //   authorization: 'authorization-text'
  //     // },
  //     disabled: isImageUploadDisabled,
  //   };
  //   document.getElementById("submit").onclick = function(e) {
  //     if (document.getElementById("profilePictureURL").value == "") {
  //       e.preventDefault();
  //       alert("Please select a file.");
  //     }
  //   }
  const handleImageUpload = (event, fieldName, isMultiple) => {
    setVal("Please Wait...");
    setisUploaded(true);
    const files = event.target.files;
    if (files.length !== 0) {
      setIsImageUploadDisabled(false);
    }
    const formData = new FormData();
    for (var i = 0; i < files.length; ++i) {
      formData.append("photos", files[i]);
    }

    fetch(baseAPIURL + "uploadGrpPic", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        var newData = { ...modifiedNonFormData };
        if (!isMultiple) {
          const url = response.data && response.data[0] && response.data[0].url;
          newData[fieldName] = url;
        } else {
          // multiple photos
          const urls = response.data && response.data.map((item) => item.url);
          if (
            !modifiedNonFormData[fieldName] ||
            modifiedNonFormData[fieldName].length <= 0
          ) {
            newData[fieldName] = urls;
          } else {
            newData[fieldName] = [...modifiedNonFormData[fieldName], ...urls];
          }
        }
        setModifiedNonFormData(newData);
        setVal("Create Group");
        setisUploaded(false);
        console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleCSVUpload = (event, fieldName, isMultiple, value, gid) => {
    const files = event;
    // const files = event.target.files
    if (files.length !== 0) {
      setIsExcelUploadDisabled(false);
    }
    const formData = new FormData();
    for (var i = 0; i < files.length; ++i) {
      formData.append("photos", files[i]);
    }
    formData.append("groupName", value.groupName);
    formData.append("access", "private");
    formData.append("groupCode", value.groupCode);
    formData.append("gid", gid);
    formData.append("userUid", value.uid);
    console.log("formdate", formData);
    fetch(baseAPIURL + "uploadGrpCSV", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        var newData = { ...modifiedNonFormData };
        if (!isMultiple) {
          const url = response.data && response.data[0] && response.data[0].url;
          newData[fieldName] = url;
        } else {
          // multiple photos
          const urls = response.data && response.data.map((item) => item.url);
          if (
            !modifiedNonFormData[fieldName] ||
            modifiedNonFormData[fieldName].length <= 0
          ) {
            newData[fieldName] = urls;
          } else {
            newData[fieldName] = [...modifiedNonFormData[fieldName], ...urls];
          }
        }
        setModifiedNonFormData(newData);
        console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleDeletePhoto = (srcToBeRemoved, fieldName, isMultiple) => {
    if (isMultiple) {
      var newData = { ...modifiedNonFormData };
      var currentURLs = newData[fieldName];
      if (currentURLs) {
        const newURLs = currentURLs.filter((src) => src != srcToBeRemoved);
        newData[fieldName] = newURLs;
        setModifiedNonFormData(newData);
      }
    } else {
      var newData = { ...modifiedNonFormData };
      newData[fieldName] = null;
      setIsImageUploadDisabled(true);
      setModifiedNonFormData(newData);
    }
  };

  const handleSwitchChange = (value, fieldName) => {
    console.log("swith value with rjah", value, "filename", fieldName);

    var newData = { ...modifiedNonFormData };
    if (value === "private") {
      newData[fieldName] = "public";
    } else {
      newData[fieldName] = "private";
    }
    setModifiedNonFormData(newData);
  };

  if (isLoading) {
    return (
      <div className="sweet-loading card">
        <div className="spinner-container">
          <ClipLoader
            className="spinner"
            sizeUnit={"px"}
            size={50}
            color={"#123abc"}
            loading={isLoading}
          />
        </div>
      </div>
    );
  }

  return (
    <Card className="Card FormCard">
      <CardBody>
        <h1 className="MainPanelHeading">Create New Group</h1>
        <Formik
          initialValues={{}}
          validate={(values) => {
            const errors = {};
            if (!values.groupName) {
              errors.groupName = "Required";
            }
            if (!values.groupAdminName) {
              errors.groupAdminName = "Required";
            }
            if (!values.groupAdminEmail) {
              errors.groupAdminEmail = "Required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                values.groupAdminEmail
              )
            ) {
              errors.groupAdminEmail = "Invalid Email";
            }

            if (!values.groupAdminPhone) {
              errors.groupAdminPhone = "Required";
            } else if (
              !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i.test(
                values.groupAdminPhone
              )
            ) {
              errors.groupAdminPhone = "Invalid Phone ";
            }
            if (!values.groupCode) {
              errors.groupCode = "Required";
            }
            //  else if (!/^\d+$/.test(values.groupCode)) {
            //   errors.groupCode = "Invalid Number";
            // }

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            if (isImageUploadDisabled === true) {
              return false;
            } else {
              createGroup(values, setSubmitting);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => {
            if (
              isImageUploadDisabled == true ||
              isExcelUploadDisabled == true
            ) {
              isSubmitting = true;
            } else {
              isSubmitting = false;
            }

            return (
              <form onSubmit={handleSubmit} method="post">
                <div className="FormFieldContainer">
                  <label className="FormLabel">Group Admin Name</label>
                  <input
                    className="FormTextField"
                    type="groupAdminName"
                    name="groupAdminName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.groupAdminName}
                  />
                  <div className="ErrorMessage">
                    {errors.groupAdminName && errors.groupAdminName}
                  </div>
                </div>
                <div className="FormFieldContainer">
                  <label className="FormLabel">Group Admin Email</label>
                  <input
                    className="FormTextField"
                    type="groupAdminEmail"
                    name="groupAdminEmail"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.groupAdminEmail}
                  />
                  <div className="ErrorMessage">
                    {errors.groupAdminEmail && errors.groupAdminEmail}
                  </div>
                </div>
                <div className="FormFieldContainer">
                  <label className="FormLabel">Group Admin Phone</label>
                  <input
                    className="FormTextField"
                    type="groupAdminPhone"
                    name="groupAdminPhone"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.groupAdminPhone}
                  />
                  <div className="ErrorMessage">
                    {errors.groupAdminPhone && errors.groupAdminPhone}
                  </div>
                </div>

                <div className="FormFieldContainer">
                  <label className="FormLabel">Group Name</label>
                  <input
                    className="FormTextField"
                    type="groupName"
                    name="groupName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.groupName}
                  />
                  <div className="ErrorMessage">
                    {errors.groupName && errors.groupName}
                  </div>
                </div>

                <div className="FormFieldContainer">
                  <label className="FormLabel">Group Code</label>
                  <input
                    className="FormTextField"
                    type="groupCode"
                    name="groupCode"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.groupCode}
                  />
                  <div className="ErrorMessage">
                    {errors.groupCode && errors.groupCode}
                  </div>
                </div>

                <div className="FormFieldContainer">
                  <label className="FormLabel">Access</label>
                  <IMToggleSwitchComponent
                    isChecked={modifiedNonFormData.access === "private"}
                    onSwitchChange={() =>
                      handleSwitchChange(
                        modifiedNonFormData["access"],
                        "access"
                      )
                    }
                  />
                  <label className="FormLabelStyling">
                    {modifiedNonFormData.access === "private"
                      ? "Private"
                      : "Public"}
                  </label>
                  <div className="ErrorMessage">
                    {errors.access && errors.access}
                  </div>
                </div>

                {modifiedNonFormData.access === "private" ? (
                  <>
                    <div className="FormFieldContainer">
                      <label className="FormLabel">Upload CSV File</label>
                      {modifiedNonFormData.groupExcelURL && (
                        <IMPhoto
                          openable
                          dismissable
                          className="photo"
                          src={modifiedNonFormData.CSVuploadURL}
                          onDelete={(src) =>
                            handleDeletePhoto(src, "CSVuploadURL", false)
                          }
                        />
                      )}
                      <input
                        className="FormFileField"
                        id="CSVuploadURL"
                        name="CSVuploadURL"
                        type="file"
                        onChange={(event) => {
                          if (event.target.files.length !== 0) {
                            setIsExcelUploadDisabled(false);
                          } else {
                            setIsExcelUploadDisabled(true);
                          }
                          console.log("my event222", event.target.files.length);
                          // handleCSVUpload(event, "CSVuploadURL", false, values);
                          setEvents(event.target.files);
                          setField("CSVuploadURL");
                          setCondition(false);
                          setValues(values);
                        }}
                      />
                      {isExcelUploadDisabled &&
                      modifiedNonFormData.access === "private" ? (
                        <div className="ErrorMessage">File Required</div>
                      ) : null}
                    </div>
                  </>
                ) : null}
                <div className="FormFieldContainer">
                  <label className="FormLabel">Group Profile Image</label>
                  {modifiedNonFormData.profilePictureURL && (
                    <IMPhoto
                      openable
                      dismissable
                      className="photo"
                      src={modifiedNonFormData.profilePictureURL}
                      onDelete={(src) =>
                        handleDeletePhoto(src, "profilePictureURL", false)
                      }
                    />
                  )}
                  <input
                    className="FormFileField"
                    id="profilePictureURL"
                    name="profilePictureURL"
                    type="file"
                    onChange={(event) => {
                      handleImageUpload(event, "profilePictureURL", false);
                    }}
                  />
                  {isImageUploadDisabled ? (
                    <div className="ErrorMessage">Profile Required</div>
                  ) : null}

                  {/* {excelUploadProps && <div>Toggle Challenge</div>} */}
                </div>
                <div className="FormFieldContainer">
                  <label className="FormLabel">Creation Date</label>
                  <IMDatePicker
                    selected={modifiedNonFormData.createdAt}
                    onChange={(toDate) => onDateChange(toDate, "createdAt")}
                  />
                </div>

                <div className="FormActionContainer">
                  <button
                    className="PrimaryButton"
                    type="submit"
                    onClick={(event) => {
                      imageSubmitClicked(event);
                      excelSubmitClicked(event);
                    }}
                    // disabled={isSubmitting}
                    disabled={isUploaded}
                  >
                    {val}
                  </button>
                  {/* {isSubmitting} */}
                </div>
              </form>
            );
          }}
        </Formik>
      </CardBody>
    </Card>
  );
};

export default AddNewGroupView;
