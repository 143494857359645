import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import IMDatePicker from "../../ui/IMDatePicker";
import { IMLocationPicker } from "../../ui/IMLocationPicker";
import { IMPhoto } from "../../../common";
import { IMImagesTableCell } from "../../ui/IMTable";
import { Card, CardBody } from "reactstrap";
import { IMToggleSwitchComponent } from "../../../common";
import axios from "axios";
import "../../ui/styles.css";

const baseAPIURL = require("../../config").baseAPIURL;

const ClientUpdateGroupView = (props) => {
  let { gid } = useParams();
console.log('fffffffffffffffffffffffffffffffffffffffffffffff');
  const [isLoading, setIsLoading] = useState(true);
  const [originalData, setOriginalData] = useState(null);
  const [modifiedNonFormData, setModifiedNonFormData] = useState({});
  const [userDocument, setUserDocument] = useState();
  const [isImageUploadDisabled, setIsImageUploadDisabled] = useState(false);
  const [val, setVal] = useState("Save");
  const [isUploaded, setisUploaded] = useState(false);

  const uid = localStorage.getItem("uid");
  useEffect(() => {
    fetch(baseAPIURL + "group/" + gid)
      .then((response) => response.json())
      .catch((err) => {
        console.log(err);
      })
      .then((data) => {
        console.log("original data", data);
        setOriginalData(data);
        if (data) {
          initializeModifieableNonFormData(data);
          userDocFunc();
        }
        // setIsLoading(false);
      });
    // var config = {
    //   method: "post",
    //   url: baseAPIURL + "getClientGroupId",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   data: {
    //     uid: uid,
    //   },
    // };

    // axios(config)
    //   .then(function (response) {
    //     setOriginalData(response.data);
    //     if (response.data) {
    //       initializeModifieableNonFormData(response.data);
    //       userDocFunc();
    //     }
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  }, [gid]);

  const userDocFunc = () => {
    fetch(baseAPIURL + "group/document/" + gid)
      .then((response) => response.json())
      .then((resp) => {
        if (resp.status == 200) {
          setUserDocument(resp.profilePictureURL);
          setIsLoading(false);
        }
      });
  };
  useEffect(() => {
    console.log("userDocument123", userDocument);
  }, [userDocument]);
  const initializeModifieableNonFormData = (originalData) => {
    var nonFormData = {
      location: originalData.location,
    };
    // if (originalData.createdAt) {
    //     nonFormData['createdAt'] = new Date(originalData.createdAt)
    // }
    if (originalData.profilePictureURL) {
      nonFormData["profilePictureURL"] = originalData.profilePictureURL;
    }
    if (originalData.photos) {
      nonFormData["photos"] = originalData.photos;
    }
    console.log(nonFormData);
    setModifiedNonFormData(nonFormData);
  };

  const saveChanges = async (modifiedData, setSubmitting) => {
    setIsLoading(true);

    const response = await fetch(baseAPIURL + "group/" + gid, {
      method: "PUT", // *GET, POST, PUT, DELETE, etc.
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...modifiedData, ...modifiedNonFormData }), // body data type must match "Content-Type" header
    })
      .then((resp) => resp.json())
      .then((res) => res);
    console.log("my res is", response);
    if (response && response.success == true) {
      fetch(baseAPIURL + "notification/pushGroupOne", {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...modifiedData, ...modifiedNonFormData }), // body data type must match "Content-Type" header
      });
    }
    setIsLoading(false);
    setSubmitting(false);
    window.location.href = "/admin/groups";
  };

  const onDateChange = (toDate, fieldName) => {
    var newData = { ...modifiedNonFormData };
    newData[fieldName] = toDate;
    setModifiedNonFormData(newData);
  };

  const onLocationChange = (addressObject, fieldName) => {
    var newData = { ...modifiedNonFormData };
    const location = {
      longitude: addressObject.location.lng,
      latitude: addressObject.location.lat,
      address: addressObject.label,
      placeID: addressObject.placeId,
      detailedAddress: addressObject.gmaps.address_components,
    };
    newData[fieldName] = location;
    setModifiedNonFormData(newData);
  };
  const imageSubmitClicked = (event) => {
    if (!modifiedNonFormData.profilePictureURL) {
      setIsImageUploadDisabled(true);
    } else {
      setIsImageUploadDisabled(false);
    }
  };
  const handleImageUpload = (event, fieldName, isMultiple) => {
    // setIsLoading(true);
    setVal('Please Wait...')
    setisUploaded(true);
    const files = event.target.files;
    const formData = new FormData();
    if (files.length !== 0) {
      setIsImageUploadDisabled(false);
    }
    for (var i = 0; i < files.length; ++i) {
      formData.append("photos", files[i]);
    }
    
    fetch(baseAPIURL + "uploadGrpPic", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        var newData = { ...modifiedNonFormData };
        if (!isMultiple) {
          const url = response.data && response.data[0] && response.data[0].url;
          newData[fieldName] = url;
        } else {
          // multiple photos
          const urls = response.data && response.data.map((item) => item.url);
          if (
            !modifiedNonFormData[fieldName] ||
            modifiedNonFormData[fieldName].length <= 0
          ) {
            newData[fieldName] = urls;
          } else {
            newData[fieldName] = [...modifiedNonFormData[fieldName], ...urls];
          }
        }
        setModifiedNonFormData(newData);
        setVal('Save')
        setisUploaded(false);
        // setIsLoading(false);
        console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleDeletePhoto = (srcToBeRemoved, fieldName, isMultiple) => {
    if (isMultiple) {
      var newData = { ...modifiedNonFormData };
      var currentURLs = newData[fieldName];
      if (currentURLs) {
        const newURLs = currentURLs.filter((src) => src != srcToBeRemoved);
        newData[fieldName] = newURLs;
        setModifiedNonFormData(newData);
      }
    } else {
      var newData = { ...modifiedNonFormData };
      newData[fieldName] = null;
      setModifiedNonFormData(newData);
      setIsImageUploadDisabled(true);
    }
  };
  const handleSwitchChange = (value, fieldName) => {
    var newData = { ...modifiedNonFormData };
    if (value === "private") {
      newData[fieldName] = "public";
    } else {
      newData[fieldName] = "private";
    }
    setModifiedNonFormData(newData);
  };

  if (isLoading) {
    return (
      <div className="sweet-loading card">
        <div className="spinner-container">
          <ClipLoader
            className="spinner"
            sizeUnit={"px"}
            size={50}
            color={"#123abc"}
            loading={isLoading}
          />
        </div>
      </div>
    );
  }
  return (
    <Card className="Card FormCard">
      <CardBody>
        <h1 className="MainPanelHeading">
          {originalData && originalData.groupName}{" "}
          {/* {originalData && originalData.groupCode} */}
        </h1>

        <Formik
          initialValues={originalData}
          validate={(values) => {
            const errors = {};
            if (!values.groupName) {
              errors.groupName = "Required";
            }
            if (!values.groupCode) {
              errors.groupCode = "Required";
            }
            if (!values.groupAdminName) {
              errors.groupAdminName = "Required";
            }
            if (!values.groupAdminEmail) {
              errors.groupAdminEmail = "Required";
            }
            if (!values.groupAdminPhone) {
              errors.groupAdminPhone = "Required";
            }
            // if (!values.groupAdminEmail) {
            //   errors.groupCode = "Required";
            // }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            if (isImageUploadDisabled === true) {
              return false
            }else{
              // console.log('helo rajesh umbhakr');
              saveChanges(values, setSubmitting);
            }
          }}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <div
                className="FormFieldContainer"
                style={{ width: "40%", float: "left" }}>
                <label className="FormLabel">Group Admin Name</label>
                <input
                  className="FormTextField"
                  type="groupAdminName"
                  name="groupAdminName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.groupAdminName}
                />
                <div className="ErrorMessage">
                  {errors.groupAdminName &&
                    errors.groupAdminName}
                </div>
              </div>

              <div
                className="FormFieldContainer"
                style={{ width: "40%", float: "left" }}>
                <label className="FormLabel">Group Admin Email</label>
                <input
                  className="FormTextField"
                  type="groupAdminEmail"
                  name="groupAdminEmail"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.groupAdminEmail}
                />
                <div className="ErrorMessage">
                  {errors.groupAdminEmail &&
                    errors.groupAdminEmail}
                </div>
              </div>

              <div
                className="FormFieldContainer"
                style={{ width: "40%", float: "left" }}>
                <label className="FormLabel">Group Admin Phone</label>
                <input
                  className="FormTextField"
                  type="groupAdminPhone"
                  name="groupAdminPhone"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.groupAdminPhone}
                />
                <div className="ErrorMessage">
                  {errors.groupAdminPhone &&
                    errors.groupAdminPhone}
                </div>
              </div>

              <div
                className="FormFieldContainer"
                style={{ width: "40%", float: "left" }}>
                <label className="FormLabel">Group Name</label>
                <input
                  className="FormTextField"
                  type="groupName"
                  name="groupName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.groupName}
                />
                <div className="ErrorMessage">
                  {errors.groupName && errors.groupName}
                </div>
              </div>

              {/* <div
                className="FormFieldContainer"
                style={{ width: "26%", float: "left" }}>
                <label className="FormLabel">Group Code</label>
                <input
                  className="FormTextField"
                  type="groupCode"
                  name="groupCode"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.groupCode}
                />
                <div className="ErrorMessage">
                  {errors.groupCode && errors.groupCode}
                </div>
              </div> */}

              {/* <div className="FormFieldContainer" style={{ width: "35%", float: "left" }}>
                  <label className="FormLabel" style={{ textAlign: "left" }}>Access</label>
                  <IMToggleSwitchComponent isChecked={modifiedNonFormData.access === 'private'} onSwitchChange={() => handleSwitchChange(modifiedNonFormData["access"], "access")} />
                  <label className="FormLabelStyling FormLabel" style={{ textAlign: "left" }}>Private</label>
                  <div className="ErrorMessage">
                      {errors.status && touched.status && errors.status}
                  </div>
              </div> */}
              <div
                className="FormFieldContainer"
                style={{ width: "40%", float: "left" }}>
                <label
                  className="FormLabel"
                  style={{ textAlign: "left", minWidth: "50px" }}>
                  Access:{" "}
                </label>
                {/* <IMToggleSwitchComponent isChecked={values.access === 'private'}  /> */}
                <label className="FormLabel" style={{ textAlign: "left" }}>
                  {values.access === "private" ||
                  values.access === "Private" ? (
                    <label>Private</label>
                  ) : (
                    <label>Public</label>
                  )}
                </label>
                {/* <div className="ErrorMessage">
                      {errors.status && touched.status && errors.status}
                  </div> */}
              </div>

              <div
                className="FormFieldContainer"
                style={{ width: "40%", float: "left" }}>
                <label className="FormLabel">Group Profile Image</label>
                {modifiedNonFormData.profilePictureURL && (
                  <IMPhoto
                    openable
                    dismissable
                    className="photo"
                    src={modifiedNonFormData.profilePictureURL}
                    onDelete={(src) =>
                      handleDeletePhoto(src, "profilePictureURL", false)
                    }
                  />
                )}
                <input
                  className="FormFileField"
                  id="profilePictureURL"
                  name="profilePictureURL"
                  type="file"
                  onChange={(event) => {
                    handleImageUpload(event, "profilePictureURL", false);
                  }}
                />
                 {isImageUploadDisabled ? (
                    <div className="ErrorMessage">Profile Required</div>
                  ) : null}
              </div>

              <div
                className="FormActionContainer"
                style={{ width: "30%", float: "left" }}>
                {/* <button
                  className="PrimaryButton"
                  type="submit"
                  // disabled={isSubmitting}
                  >
                  Save
                </button> */}
                <button
                    className="PrimaryButton"
                    type="submit"
                    onClick={(event) => {
                      imageSubmitClicked(event);
                    }}
                    // disabled={isSubmitting}
                    disabled={isUploaded}
                    >
                    {val}
                  </button>
              </div>
            </form>
          )}
        </Formik>
      </CardBody>
    </Card>
  );
};

export default ClientUpdateGroupView;
