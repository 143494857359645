// import React, { useEffect, useState,useMemo } from "react";
// import { Formik, validateYupSchema } from "formik";
// import { useParams } from "react-router-dom";
// import { ClipLoader } from "react-spinners";
// import IMDatePicker from "../../ui/IMDatePicker";
// import { IMLocationPicker } from "../../ui/IMLocationPicker";
// import { IMPhoto } from "../../../common";
// import { IMImagesTableCell } from "../../ui/IMTable";
// import { Card, CardBody } from "reactstrap";
// import moment from "moment";
// import "../../ui/styles.css";

// import firebase from "firebase";
// import { useHistory } from "react-router-dom";
// import {
//     Row,
//     Col
// } from "reactstrap";

// import { useTable, usePagination } from "react-table";


// const baseAPIURL = require("../../config").baseAPIURL;

// const UpdateUserView = (props) => {
//   let { uid } = useParams();

//   const [isLoading, setIsLoading] = useState(true);
//   const [originalData, setOriginalData] = useState(null);
//   const [modifiedNonFormData, setModifiedNonFormData] = useState({});
//   const [userDocument, setUserDocument] = useState();
//   const [data, setData] = useState([]);
// const [groups, setUsers] = useState([]);
// const [controlledPageCount, setControlledPageCount] = useState(0)

//   // let dateNew = new Date();
//   // let date = moment(dateNew).format("YYYY-MM-DD");

//   const groupsColumns = [
//     {
//         Header: "User Name",
//         accessor: "name",
//     },
//     {
//         Header: "Start Point",
//         accessor: "startPoint",
//     },
//     {
//         Header: "End Point",
//         accessor: "endPoint",
//     },
//     {
//       Header: "Travel Means",
//       accessor: "travelMeans",
//   },
//     {
//         Header: "PROFILE PICTURE",
//         accessor:  data => {
//             return(
//             <IMImagesTableCell singleImageURL={data.profilePic} />
//         )}
//     },
//     // {
//     //     Header: "Actions",
//     //     accessor: "actions",
//     //     Cell: data => (
//     //         <ActionsItemView data={data} />
//     //     )
//     // }
// ]
//   const columns = useMemo(
//     () => groupsColumns,
//     []
//   )
//   const {
//     getTableProps,
//     getTableBodyProps,
//     headerGroups,
//     prepareRow,
//     //pagination
//     page,
//     canPreviousPage,
//     canNextPage,
//     pageOptions,
//     pageCount,
//     gotoPage,
//     nextPage,
//     previousPage,
//     setPageSize,
//     // Get the state from the instance
//     state: { pageIndex, pageSize },
//   } = useTable(
//     {
//       columns,
//       data: groups,
//       initialState: { pageIndex: 0 },
//       manualPagination: true,
//       pageCount: controlledPageCount,
//     }, usePagination
//   )
// // console.log('groud data',data);
//   let issubmitted = true;
//   // console.log('aaaaaaaaaaa', date);
//   useEffect(() => {
//     fetch(baseAPIURL + "user/" + uid)
//       .then((response) => response.json())
//       .catch((err) => {
//         console.log(err);
//       })
//       .then((data) => {
//         let dateNew = data.dob;
//         let date = moment(dateNew).format("YYYY-MM-DD");
//         let tName = data.fullName?.split(" ", 1)[0];
//         data.dob = date;
//         const mainData = { ...data, firstName: tName };
//         console.log("original data", mainData);
//         setOriginalData(mainData);
//         if (mainData) {
//           initializeModifieableNonFormData(mainData);
//           userDocFunc();
//         }
//         // setIsLoading(false);
//       });
//   }, [uid]);

//   const userDocFunc = () => {
//     fetch(baseAPIURL + "user/document/" + uid)
//       .then((response) => response.json())
//       .then((resp) => {
//         if (resp.status == 200) {
//           setUserDocument(resp.uploadDocument);
//           setIsLoading(false);
//         }
//       });
//   };
//   useEffect(() => {}, [userDocument]);
//   const initializeModifieableNonFormData = (originalData) => {
//     var nonFormData = {
//       location: originalData.location,
//     };
//     // if (originalData.createdAt) {
//     //     nonFormData['createdAt'] = new Date(originalData.createdAt)
//     // }
//     if (originalData.profilePictureURL) {
//       nonFormData["profilePictureURL"] = originalData.profilePictureURL;
//     }
//     if (originalData.photos) {
//       nonFormData["photos"] = originalData.photos;
//     }
//     console.log(nonFormData);
//     setModifiedNonFormData(nonFormData);
//   };
//   useEffect(() => {
//     fetch(baseAPIURL + "user/postwalkhistory/" + uid)
//     .then(response => response.json())
//       .then(data => {
//         const groups = data;
//         setData(groups);
//       })
//       .catch(err => { console.log(err) });
//   }, [uid]);
//   useEffect(() => {
//     const startRow = pageSize * pageIndex
//     const endRow = startRow + pageSize
  
//     setUsers(data.slice(startRow, endRow))
//     setControlledPageCount(Math.ceil(data.length / pageSize))
  
//   }, [pageIndex, pageSize, data])
//   const saveChanges = async (modifiedData, setSubmitting) => {

//     const tdateNew = new Date(modifiedData.dob);
//     const tdate = moment(modifiedData.dob).format("DD MMM YYYY").toUpperCase();

//     const currentDate = new Date();

//     const mainData = {
//       ...modifiedData,
//       ...modifiedNonFormData,
//       dob: tdate,
//       documentation: true,
//       personalDetails: true,
//       profileComplete: true,
//       transportPreferences: true,
//       verifyProfile: "true",
//       verifyByAdmin:true,
//     };
//     console.log("modifiedNonFormData", mainData);
//     // alert("sss");
//     // return;
//     const response = await fetch(baseAPIURL + "user/" + uid, {
//       method: "PUT", // *GET, POST, PUT, DELETE, etc.
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(mainData), // body data type must match "Content-Type" header
//     })
//       .then((resp) => resp.json())
//       .then((res) => res);
//     console.log("my res is", response);
//     if (response && response.success == true) {
//       fetch(baseAPIURL + "notification/pushOne", {
//         method: "POST", // *GET, POST, PUT, DELETE, etc.
//         headers: {
//           Accept: "application/json",
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ ...modifiedData, ...modifiedNonFormData }), // body data type must match "Content-Type" header
//       }).then(()=>
//       {
//         window.location.href = "/admin/users";
//       }
//       )

//       // console.log('qqqq',uid);

//       // const postwalkresponse = await fetch(baseAPIURL + "user/postwalk", {
//       //   method: "POST",
//       //   headers: {
//       //     Accept: "application/json",
//       //     "Content-Type": "application/json",
//       //   },
//       //   body: JSON.stringify({ uid: uid }),
//       // });
//       // console.log('postwalkresponse',postwalkresponse);
      
//     }
//     // firebase.firestore().collection("Post_Walk_Location").doc(uid).set({
//     //   uid: uid,
//     // });
//     setSubmitting(false);
//   };

//   const onDateChange = (toDate, fieldName) => {
//     var newData = { ...modifiedNonFormData };
//     newData[fieldName] = toDate;
//     setModifiedNonFormData(newData);
//   };

//   const onLocationChange = (addressObject, fieldName) => {
//     var newData = { ...modifiedNonFormData };
//     const location = {
//       longitude: addressObject.location.lng,
//       latitude: addressObject.location.lat,
//       address: addressObject.label,
//       placeID: addressObject.placeId,
//       detailedAddress: addressObject.gmaps.address_components,
//     };
//     newData[fieldName] = location;
//     setModifiedNonFormData(newData);
//   };

//   const handleImageUpload = (event, fieldName, isMultiple) => {
//     const files = event.target.files;
//     const formData = new FormData();
//     for (var i = 0; i < files.length; ++i) {
//       formData.append("photos", files[i]);
//     }

//     fetch(baseAPIURL + "upload", {
//       method: "POST",
//       body: formData,
//     })
//       .then((response) => response.json())
//       .then((response) => {
//         var newData = { ...modifiedNonFormData };
//         if (!isMultiple) {
//           const url = response.data && response.data[0] && response.data[0].url;
//           newData[fieldName] = url;
//         } else {
//           // multiple photos
//           const urls = response.data && response.data.map((item) => item.url);
//           if (
//             !modifiedNonFormData[fieldName] ||
//             modifiedNonFormData[fieldName].length <= 0
//           ) {
//             newData[fieldName] = urls;
//           } else {
//             newData[fieldName] = [...modifiedNonFormData[fieldName], ...urls];
//           }
//         }
//         console.log("dd", newData);
//         setModifiedNonFormData(newData);
//         console.log(response);
//       })
//       .catch((error) => {
//         console.error(error);
//       });
//   };

//   const handleDeletePhoto = (srcToBeRemoved, fieldName, isMultiple) => {
//     if (isMultiple) {
//       var newData = { ...modifiedNonFormData };
//       var currentURLs = newData[fieldName];
//       if (currentURLs) {
//         const newURLs = currentURLs.filter((src) => src != srcToBeRemoved);
//         newData[fieldName] = newURLs;
//         setModifiedNonFormData(newData);
//       }
//     } else {
//       var newData = { ...modifiedNonFormData };
//       newData[fieldName] = null;
//       setModifiedNonFormData(newData);
//     }
//   };

//   if (isLoading) {
//     return (
//       <div className="sweet-loading card">
//         <div className="spinner-container">
//           <ClipLoader
//             className="spinner"
//             sizeUnit={"px"}
//             size={50}
//             color={"#123abc"}
//             loading={isLoading}
//           />
//         </div>
//       </div>
//     );
//   }
//   const photos = {
//     govtIdBack:
//       "https://firebasestorage.googleapis.com/v0/b/a-safer-walk.appspot.com/o/ipYCJg2PcTX4OB7vrFk9WiY9Pcp1%2FDocument%2F1649322136820?alt=media&token=fad7bc35-9bbf-440f-8dd2-c5bd8767243f",
//     govtIdFront:
//       "https://firebasestorage.googleapis.com/v0/b/a-safer-walk.appspot.com/o/ipYCJg2PcTX4OB7vrFk9WiY9Pcp1%2FDocument%2F1649322136567?alt=media&token=ef3bb69a-467d-46bf-b391-f8b65dc70694",
//     profilePic:
//       "https://firebasestorage.googleapis.com/v0/b/a-safer-walk.appspot.com/o/ipYCJg2PcTX4OB7vrFk9WiY9Pcp1%2FDocument%2F1649322136838?alt=media&token=682ec220-9d9e-4daa-8816-cdc3c56cd7a5",
//     verificationPic:
//       "https://firebasestorage.googleapis.com/v0/b/a-safer-walk.appspot.com/o/ipYCJg2PcTX4OB7vrFk9WiY9Pcp1%2FDocument%2F1649322137117?alt=media&token=4225cc44-5eb6-4e7d-a72d-ba94dafbb435",
//   };
//   return (
//     <>
//     <Card className="Card FormCard">
//       <CardBody>
//         <h1 className="MainPanelHeading">
//           {originalData && originalData.firstName}{" "}
//           {originalData && originalData.lastName}
//         </h1>

//         <Formik
//           initialValues={originalData}
//           validate={(values) => {
//             const errors = {};

//             if (!values.gmail) {
//               errors.gmail = "Required";
//             } else if (
//               !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.gmail)
//             ) {
//               errors.gmail = "Invalid email address";
//             }
//             if (!values.fullName) {
//               errors.fullName = "Required";
//             }
//             if (!values.firstName) {
//               errors.firstName = "Required";
//             }
//             if (!values.dob || values.dob ==="Invalid date") {
//               errors.dob = "Required";
//             }
//             if (!values.phone) {
//               errors.phone = "Required";
//             }
//             // if (!values.gmail) {
//             //   errors.gmail = "Required";
//             // }
//             if (!values.govId) {
//               errors.govId = "Required";
//             }
//             if (!values.firstName) {
//               errors.firstName = "Required";
//             }
//             if (!values.firstName) {
//               errors.firstName = "Required";
//             }

//             if (!values.verifyProfile) {
//               errors.verifyProfile = "Required";
//             }
//             if (!values.profilePic) {
//               errors.profilePic = "Required";
//             }
//             if (values.verifyProfile == "false") {
//               errors.verifyProfile = "select Verified";
//               // issubmitted = true;
//               // console.log('in if');
//             }
//             return errors;
//           }}
//           onSubmit={(values, { setSubmitting }) => {
//             // if (!userDocument?.gestureGiven) {
//             //   return false;
//             // }
//             saveChanges(values, setSubmitting);
//           }}>
//           {({
//             values,
//             errors,
//             touched,
//             handleChange,
//             handleBlur,
//             handleSubmit,
//             isSubmitting,
//             /* and other goodies */
//           }) => {
//             if (values.verifyProfile == "false") {
//               issubmitted = true;
//               // console.log("in if", issubmitted);
//             } else {
//               // console.log('in else', issubmitted);
//               issubmitted = false;
//             }
//             // console.log("values with rajesh", errors);
//             return (
//               <form onSubmit={handleSubmit}>
//                 <div className="dflex row">
//                   <div className="dflex row">
//                     <div
//                       className="FormFieldContainer dflex column"
//                       style={{ width: "30%", float: "left" }}>
//                       <label className="FormLabel">Full Name</label>
//                       <input
//                         className="FormTextField"
//                         type="fullName"
//                         name="fullName"
//                         onChange={handleChange}
//                         onBlur={handleBlur}
//                         value={values.fullName}
//                       />
//                       <div className="ErrorMessage">
//                         {errors.fullName && errors.fullName}
//                       </div>
//                     </div>
//                     <div
//                       className="dflex column FormFieldContainer"
//                       style={{
//                         width: "30%",
//                         float: "right",
//                         paddingLeft: "2pc",
//                       }}>
//                       <label className="FormLabel">First Name</label>
//                       <input
//                         className="FormTextField"
//                         type="firstName"
//                         name="firstName"
//                         onChange={handleChange}
//                         onBlur={handleBlur}
//                         value={values.firstName}
//                         // value={(values?.fullName).split(' ')[0]}
//                       />
//                       <div className="ErrorMessage">
//                         {errors.firstName  &&
//                           errors.firstName}
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="dflex row">
//                   <div className="dflex row">
//                     <div
//                       className="dflex column FormFieldContainer"
//                       style={{ width: "30%", float: "left" }}>
//                       <label className="FormLabel">DOB</label>
//                       <input
//                         className="FormTextField"
//                         type="date"
//                         name="dob"
//                         onChange={handleChange}
//                         onBlur={handleBlur}
//                         value={values.dob}
//                       />
//                       <div className="ErrorMessage">
//                         {errors.dob && touched.dob && errors.dob}
//                       </div>
//                     </div>
//                     <div
//                       className="dflex column FormFieldContainer"
//                       style={{
//                         width: "30%",
//                         float: "right",
//                         paddingLeft: "2pc",
//                       }}>
//                       <label className="FormLabel">Phone</label>
//                       <input
//                         className="FormTextField"
//                         type="phone"
//                         name="phone"
//                         onChange={handleChange}
//                         onBlur={handleBlur}
//                         value={values.phone}
//                         disabled={true}
//                       />
//                       <div className="ErrorMessage">
//                         {errors.phone && touched.phone && errors.phone}
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="dflex row">
//                   <div className="dflex row">
//                     <div
//                       className="dflex column FormFieldContainer"
//                       style={{ width: "30%", float: "left" }}>
//                       <label className="FormLabel">Gmail</label>
//                       <input
//                         className="FormTextField"
//                         type="gmail"
//                         name="gmail"
//                         onChange={handleChange}
//                         onBlur={handleBlur}
//                         value={values.gmail}
//                       />
//                       <div className="ErrorMessage" >
//                         {errors.gmail  && errors.gmail}
//                       </div>
//                     </div>
//                     <div
//                       className="dflex column FormFieldContainer"
//                       style={{
//                         width: "30%",
//                         float: "right",
//                         paddingLeft: "2pc",
//                       }}>
//                       <label className="FormLabel">Gov ID</label>
//                       <input
//                         className="FormTextField"
//                         type="govId"
//                         name="govId"
//                         onChange={handleChange}
//                         onBlur={handleBlur}
//                         value={values.govId}
//                       />
//                       <div className="ErrorMessage">
//                         {errors.govId && errors.govId}
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="dflex row">
//                   <div className="dflex row">
//                     <div
//                       className="dflex column FormFieldContainer"
//                       style={{ width: "20%", float: "left" }}>
//                       <label
//                         className="FormLabel"
//                         style={{ marginLeft: "-32px" }}>
//                         Profile Pic
//                       </label>
//                       <div style={{ paddingLeft: "2pc" }}>
//                         <IMImagesTableCell
//                           singleImageURL={values?.profilePic}
//                         />
//                       </div>
//                       <div className="ErrorMessage">
//                         {errors.profilePic &&
//                           errors.profilePic}
//                       </div>
//                     </div>
//                     {/* <div
//                       className="dflex column FormFieldContainer"
//                       style={{
//                         width: "20%",
//                         float: "left",
//                         paddingLeft: "3pc",
//                       }}>
//                       <label
//                         className="FormLabel"
//                         style={{ marginLeft: "-53px" }}>
//                         Gesture Image
//                       </label>

//                       <div> */}
//                         {/* <IMImagesTableCell
//                           singleImageURL={values?.profilePic}
//                         /> */}
//                         {/* <IMImagesTableCell
//                           singleImageURL={userDocument?.verificationPic}
//                         />
//                         <span>{userDocument?.gestureGiven}</span>
//                       </div>
//                       <div className="ErrorMessage">
//                         {userDocument?.gestureGiven ? null : "Gesture Image Required"}
//                       </div>
//                     </div> */}
//                        {userDocument?.govtIdFront ?
//                     <div
//                       className="dflex column FormFieldContainer"
//                       style={{ width: "20%", float: "left" }}>
//                       <div>
//                         <label className="FormLabel">Gov ID Document</label>

//                         <div className="" style={{ marginLeft: "14px" }}>
//                           {/* <IMImagesTableCell
//                             singleImageURL={userDocument?.govtIdBack}
//                           /> */}

//                           <IMImagesTableCell
//                             singleImageURL={userDocument?.govtIdFront}
//                           />

//                           {/* <IMImagesTableCell
//                             singleImageURL={userDocument?.profilePic}
//                           /> */}

//                           {/* <IMImagesTableCell
//                             singleImageURL={userDocument?.verificationPic}
//                           /> */}
//                         </div>
//                       </div>
//                     </div>
//                     : null}
//                   </div>
//                 </div>

//                 <div className="dflex row">
//                   <div className="dflex row">
//                     <div
//                       className="dflex column FormFieldContainer"
//                       style={{ width: "30%" }}>
//                       <label className="FormLabel">Status</label>
//                       <select
//                         style={{ height: "40px" }}
//                         className="FormTextField"
//                         name="verifyProfile"
//                         onChange={handleChange}
//                         onBlur={handleBlur}>
//                         <option value="">Select</option>
//                         <option
//                           selected={
//                             values.verifyProfile == "false" ||
//                             values.verifyProfile == false
//                               ? "selected"
//                               : ""
//                           }
//                           value="false">
//                           Unverified
//                         </option>
//                         <option
//                           selected={
//                             values.verifyProfile == "true" ||
//                             values.verifyProfile == true
//                               ? "selected"
//                               : ""
//                           }
//                           value="true">
//                           Verified
//                         </option>
//                       </select>
//                       <div className="ErrorMessage" hidden={!issubmitted}>
//                         select verified
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="dflex row">
//                   <div className="dflex row">
//                     <div className="dflex column FormActionContainer">
//                       <button
//                         className="PrimaryButton"
//                         type="submit"
//                         // disabled={issubmitted}
//                         >
//                         Save user
//                       </button>
//                     </div>
//                   </div>
//                 </div>
//               </form>
//             );
//           }}
//         </Formik>
//       </CardBody>
//     </Card>
//     <Row>
// 		<Col md="12">
// 			<div className="Card">
// 				<div className="CardHeader">
// 					<h4 style={{fontSize: "18px"}}className='MainPanelHeading'>Total Walk History : {page.length} </h4>
// 				</div>
// 				<div className="CardBody">
// 					<div className="TableContainer">
// 						<table className="Table" {...getTableProps()}>
// 							<thead>
// 								{headerGroups.map(headerGroup => (
// 									<tr {...headerGroup.getHeaderGroupProps()}>
// 										{headerGroup.headers.map(column => (
// 											<th {...column.getHeaderProps()}>{column.render('Header')}</th>
// 										))}
// 									</tr>
// 								))}
// 							</thead>
// 							<tbody {...getTableBodyProps()}>
// 								{page.map((row, i) => {
// 									prepareRow(row)
// 									return (
// 										<tr {...row.getRowProps()}>
// 											{row.cells.map(cell => {
// 												return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
// 											})}
// 										</tr>
// 									)
// 								})}
// 								<tr>
// 									{isLoading ? (
// 										<td colSpan={groupsColumns.length - 1}>
// 											<p>
// 												Loading...
// 											</p>
// 										</td>
// 									) : (
// 											<td colSpan={groupsColumns.length - 1}>
// 												<p className="PaginationDetails">
// 													Showing {page.length} of {data.length}{' '} results
// 												</p>
// 											</td>
// 										)}
// 								</tr>
// 							</tbody>
// 						</table>
// 						<div className="Pagination">
// 							<div className="LeftPaginationButtons">
// 								<button onClick={() => gotoPage(0)} className="PaginationButton" disabled={!canPreviousPage}>
// 									<i className="fa fa-angle-double-left"></i>
// 								</button>{' '}
// 								<button onClick={() => previousPage()} className="PaginationButton" disabled={!canPreviousPage}>
// 									<i className="fa fa-angle-left"></i>
// 								</button>
// 							</div>
// 							<div className="CenterPaginationButtons">
// 								<span>
// 									Page{' '}
// 									<strong>
// 										{pageIndex + 1} of {pageOptions.length}
// 									</strong>{' '}
// 								</span>
// 								<span>
// 									| Go to page:{' '}
// 									<input
// 										type="number"
// 										defaultValue={pageIndex + 1}
// 										onChange={e => {
// 											const page = e.target.value ? Number(e.target.value) - 1 : 0
// 											gotoPage(page)
// 										}}
// 										style={{ width: '100px' }}
// 									/>
// 								</span>{' '}
// 								<select
// 									value={pageSize}
// 									onChange={e => {
// 										setPageSize(Number(e.target.value))
// 									}}
// 								>
// 									{[10, 20, 30, 40, 50].map(pageSize => (
// 										<option key={pageSize} value={pageSize}>
// 											Show {pageSize}
// 										</option>
// 									))}
// 								</select>
// 							</div>
// 							<div className="RightPaginationButtons">
// 								<button onClick={() => nextPage()} className="PaginationButton" disabled={!canNextPage}>
// 									<i className="fa fa-angle-right"></i>
// 								</button>{' '}
// 								<button onClick={() => gotoPage(pageCount - 1)} className="PaginationButton" disabled={!canNextPage}>
// 									<i className="fa fa-angle-double-right"></i>
// 								</button>
// 							</div>
// 						</div>
// 					</div>
// 				</div>
// 			</div>
// 		</Col>
// 	</Row>
//   </>
//   );
// };

// export default UpdateUserView;

import React, { useEffect, useState, useMemo } from "react";
import { Formik, validateYupSchema } from "formik";
import { useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import IMDatePicker from "../../ui/IMDatePicker";
import { IMLocationPicker } from "../../ui/IMLocationPicker";
import { IMPhoto } from "../../../common";
import { IMImagesTableCell } from "../../ui/IMTable";
import { Card, CardBody } from "reactstrap";
import moment from "moment";
import axios from "axios";
import "../../ui/styles.css";

import firebase from "firebase";
import { useHistory } from "react-router-dom";
import { Row, Col } from "reactstrap";

import { useTable, usePagination } from "react-table";
import { getToken } from "../../../onboarding";

const baseAPIURL = require("../../config").baseAPIURL;

const UpdateUserView = (props) => {
  let { uid } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [originalData, setOriginalData] = useState(null);
  const [modifiedNonFormData, setModifiedNonFormData] = useState({});
  const [userDocument, setUserDocument] = useState();
  const [data, setData] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [groups, setUsers] = useState([]);
  const [groupIsSelected, setGroupIsSelected] = useState('selected');
  const [controlledPageCount, setControlledPageCount] = useState(0);
  // let dateNew = new Date();
  // let date = moment(dateNew).format("YYYY-MM-DD");

  const groupsColumns = [
    {
      Header: "User Name",
      accessor: "name",
    },
    {
      Header: "Start Point",
      accessor: "startPoint",
    },
    {
      Header: "End Point",
      accessor: "endPoint",
    },
    {
      Header: "Travel Means",
      accessor: "travelMeans",
    },
    {
      Header: "PROFILE PICTURE",
      accessor: (data) => {
        return <IMImagesTableCell singleImageURL={data.profilePic} />;
      },
    },
    // {
    //     Header: "Actions",
    //     accessor: "actions",
    //     Cell: data => (
    //         <ActionsItemView data={data} />
    //     )
    // }
  ];
  const columns = useMemo(() => groupsColumns, []);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    //pagination
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: groups,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    usePagination
  );
  // console.log('groud data',data);
  let issubmitted = true;
  // console.log('aaaaaaaaaaa', date);
//get group list strat
  useEffect(() => {
    const token = getToken();
    const getuid = localStorage.getItem("uid");
    const extraQueryParams = null;
    setIsLoading(true);

    const formData = new FormData();
    formData.append("uid", getuid);
    formData.append("isGroup", "private");
    fetch(baseAPIURL + "getGroupList" + (extraQueryParams ? extraQueryParams : ""), {
      method: "POST",
      headers: { Authorization: token },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        const groups = data.groups;
        console.log("groupsgroupsgroups",groups);
        setGroupList(groups);

        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
//get group list end


  useEffect(() => {
    fetch(baseAPIURL + "user/" + uid)
      .then((response) => response.json())
      .catch((err) => {
        console.log(err);
      })
      .then((data) => {
        let dateNew = data.dob;
        let date = moment(dateNew).format("YYYY-MM-DD");
        let tName = data.fullName?.split(" ", 1)[0];
        data.dob = date;
        const mainData = { ...data, firstName: tName };
        console.log("original data", mainData);
        setOriginalData(mainData);
        if (mainData.userPrivateGroupId && mainData.userprivate == true) {
          setGroupIsSelected(mainData.userPrivateGroupId)
        }
        
        if (mainData) {
          initializeModifieableNonFormData(mainData);
          userDocFunc();
        }
        // setIsLoading(false);
      });
  }, [uid]);

  const userDocFunc = () => {
    fetch(baseAPIURL + "user/document/" + uid)
      .then((response) => response.json())
      .then((resp) => {
        if (resp.status == 200) {
          setUserDocument(resp.uploadDocument);
          setIsLoading(false);
        }
      });
  };
  useEffect(() => {}, [userDocument]);
  const initializeModifieableNonFormData = (originalData) => {
    var nonFormData = {
      location: originalData.location,
    };
    // if (originalData.createdAt) {
    //     nonFormData['createdAt'] = new Date(originalData.createdAt)
    // }
    if (originalData.profilePictureURL) {
      nonFormData["profilePictureURL"] = originalData.profilePictureURL;
    }
    if (originalData.photos) {
      nonFormData["photos"] = originalData.photos;
    }
    console.log(nonFormData);
    setModifiedNonFormData(nonFormData);
  };
  useEffect(() => {
    fetch(baseAPIURL + "user/postwalkhistory/" + uid)
      .then((response) => response.json())
      .then((data) => {
        const groups = data;
        setData(groups);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [uid]);

  
  useEffect(() => {
    const startRow = pageSize * pageIndex;
    const endRow = startRow + pageSize;

    setUsers(data.slice(startRow, endRow));
    setControlledPageCount(Math.ceil(data.length / pageSize));
  }, [pageIndex, pageSize, data]);
  const saveChanges = async (modifiedData, setSubmitting) => {
    const tdateNew = new Date(modifiedData.dob);
    const tdate = moment(modifiedData.dob).format("DD MMM YYYY").toUpperCase();

    const currentDate = new Date();

    const mainData = {
      ...modifiedData,
      ...modifiedNonFormData,
      dob: tdate,
      documentation: true,
      personalDetails: true,
      profileComplete: true,
      transportPreferences: true,
      verifyProfile: "true",
      verifyByAdmin: true,
      unassigned: groupIsSelected == 'selected'? false:true,
      userPrivateGroupId : groupIsSelected == 'selected'? '':groupIsSelected
    };
    console.log("modifiedNonFormData", mainData);
    // alert("sss");
    // return;
    setIsLoading(true);
    const response = await fetch(baseAPIURL + "user/" + uid, {
      method: "PUT", // *GET, POST, PUT, DELETE, etc.
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(mainData), // body data type must match "Content-Type" header
    })
      .then((resp) => resp.json())
      .then((res) => res);
    console.log("my res is", response);
    if (response && response.success == true) {
      fetch(baseAPIURL + "notification/pushOne", {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...modifiedData, ...modifiedNonFormData }), // body data type must match "Content-Type" header
      }).then(() => {
        if (originalData.userprivate == true) {
          joinPrivateGroup()
        } else {
          setIsLoading(false);
          window.location.href = "/admin/users";
        }
      });

      // console.log('qqqq',uid);

      // const postwalkresponse = await fetch(baseAPIURL + "user/postwalk", {
      //   method: "POST",
      //   headers: {
      //     Accept: "application/json",
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify({ uid: uid }),
      // });
      // console.log('postwalkresponse',postwalkresponse);
    }
    // firebase.firestore().collection("Post_Walk_Location").doc(uid).set({
    //   uid: uid,
    // });
    // setSubmitting(false);
  };

  const joinPrivateGroup =()=>{
console.log("iiiiiiiiissssssssssspppppppprrrrr");
    var data = new FormData();
data.append('gid', groupIsSelected);
data.append('gov_id', originalData.govId);
data.append('userUid', originalData.uid);

var config = {
  method: 'post',
  url: `${baseAPIURL}addPrivateUserToPrivateGroup`,
  headers: { 
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  data : data
};

axios(config)
.then(function (response) {
  var isDatas = response.data
  setIsLoading(false);
  if (isDatas.success == true) {
    window.location.href = "/admin/users";
  } else {
    alert(isDatas.message)
  }
})
.catch(function (error) {
  console.log(error);
});
  }

  const onDateChange = (toDate, fieldName) => {
    var newData = { ...modifiedNonFormData };
    newData[fieldName] = toDate;
    setModifiedNonFormData(newData);
  };

  const onLocationChange = (addressObject, fieldName) => {
    var newData = { ...modifiedNonFormData };
    const location = {
      longitude: addressObject.location.lng,
      latitude: addressObject.location.lat,
      address: addressObject.label,
      placeID: addressObject.placeId,
      detailedAddress: addressObject.gmaps.address_components,
    };
    newData[fieldName] = location;
    setModifiedNonFormData(newData);
  };

  const handleImageUpload = (event, fieldName, isMultiple) => {
    const files = event.target.files;
    const formData = new FormData();
    for (var i = 0; i < files.length; ++i) {
      formData.append("photos", files[i]);
    }

    fetch(baseAPIURL + "upload", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        var newData = { ...modifiedNonFormData };
        if (!isMultiple) {
          const url = response.data && response.data[0] && response.data[0].url;
          newData[fieldName] = url;
        } else {
          // multiple photos
          const urls = response.data && response.data.map((item) => item.url);
          if (
            !modifiedNonFormData[fieldName] ||
            modifiedNonFormData[fieldName].length <= 0
          ) {
            newData[fieldName] = urls;
          } else {
            newData[fieldName] = [...modifiedNonFormData[fieldName], ...urls];
          }
        }
        console.log("dd", newData);
        setModifiedNonFormData(newData);
        console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleDeletePhoto = (srcToBeRemoved, fieldName, isMultiple) => {
    if (isMultiple) {
      var newData = { ...modifiedNonFormData };
      var currentURLs = newData[fieldName];
      if (currentURLs) {
        const newURLs = currentURLs.filter((src) => src != srcToBeRemoved);
        newData[fieldName] = newURLs;
        setModifiedNonFormData(newData);
      }
    } else {
      var newData = { ...modifiedNonFormData };
      newData[fieldName] = null;
      setModifiedNonFormData(newData);
    }
  };

  if (isLoading) {
    return (
      <div className="sweet-loading card">
        <div className="spinner-container">
          <ClipLoader
            className="spinner"
            sizeUnit={"px"}
            size={50}
            color={"#123abc"}
            loading={isLoading}
          />
        </div>
      </div>
    );
  }
  const photos = {
    govtIdBack:
      "https://firebasestorage.googleapis.com/v0/b/a-safer-walk.appspot.com/o/ipYCJg2PcTX4OB7vrFk9WiY9Pcp1%2FDocument%2F1649322136820?alt=media&token=fad7bc35-9bbf-440f-8dd2-c5bd8767243f",
    govtIdFront:
      "https://firebasestorage.googleapis.com/v0/b/a-safer-walk.appspot.com/o/ipYCJg2PcTX4OB7vrFk9WiY9Pcp1%2FDocument%2F1649322136567?alt=media&token=ef3bb69a-467d-46bf-b391-f8b65dc70694",
    profilePic:
      "https://firebasestorage.googleapis.com/v0/b/a-safer-walk.appspot.com/o/ipYCJg2PcTX4OB7vrFk9WiY9Pcp1%2FDocument%2F1649322136838?alt=media&token=682ec220-9d9e-4daa-8816-cdc3c56cd7a5",
    verificationPic:
      "https://firebasestorage.googleapis.com/v0/b/a-safer-walk.appspot.com/o/ipYCJg2PcTX4OB7vrFk9WiY9Pcp1%2FDocument%2F1649322137117?alt=media&token=4225cc44-5eb6-4e7d-a72d-ba94dafbb435",
  };
  return (
    <>
      <Card className="Card FormCard">
        <CardBody>
          <h1 className="MainPanelHeading">
            {originalData && originalData.firstName}{" "}
            {originalData && originalData.lastName}
          </h1>

          <Formik
            initialValues={originalData}
            validate={(values) => {
              const errors = {};

              if (!values.gmail) {
                errors.gmail = "Required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.gmail)
              ) {
                errors.gmail = "Invalid email address";
              }
              if (!values.fullName) {
                errors.fullName = "Required";
              }
              if (!values.firstName) {
                errors.firstName = "Required";
              }
              if (!values.dob || values.dob === "Invalid date") {
                errors.dob = "Required";
              }
              if (!values.phone) {
                errors.phone = "Required";
              }
              // if (!values.gmail) {
              //   errors.gmail = "Required";
              // }
              if (!values.govId) {
                errors.govId = "Required";
              }
              if (!values.firstName) {
                errors.firstName = "Required";
              }
              if (!values.firstName) {
                errors.firstName = "Required";
              }

              if (!values.verifyProfile) {
                errors.verifyProfile = "Required";
              }
              // if (!values.unassigned ) {
              //   errors.unassigned = "select Group Required";
              // }
              // if (groupIsSelected == 'selected' ) {
              //   errors.unassigned = "select Group Required";
              // }
              if (groupIsSelected == 'selected' && originalData.userprivate == true) {
                // setGroupIsSelected(originalData.userPrivateGroupId)
                errors.unassigned = "select Group Required";
              }
              if (!values.profilePic) {
                errors.profilePic = "Required";
              }
              if (values.verifyProfile == "false") {
                errors.verifyProfile = "select Verified";
                // issubmitted = true;
                // console.log('in if');
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              // if (!userDocument?.gestureGiven) {
              //   return false;
              // }
              saveChanges(values, setSubmitting);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => {
              if (values.verifyProfile == "false") {
                issubmitted = true;
                // console.log("in if", issubmitted);
              } else if (values.unassigned == false) {
                issubmitted = true;
                // console.log("in if", issubmitted);
              } else {
                // console.log('in else', issubmitted);
                issubmitted = false;
              }
              // console.log("values with rajesh", errors);
              return (
                <form onSubmit={handleSubmit}>
                  <div className="dflex row">
                    <div className="dflex row">
                      <div
                        className="FormFieldContainer dflex column"
                        style={{ width: "30%", float: "left" }}
                      >
                        <label className="FormLabel">Full Name</label>
                        <input
                          className="FormTextField"
                          type="fullName"
                          name="fullName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.fullName}
                        />
                        <div className="ErrorMessage">
                          {errors.fullName && errors.fullName}
                        </div>
                      </div>
                      <div
                        className="dflex column FormFieldContainer"
                        style={{
                          width: "30%",
                          float: "right",
                          paddingLeft: "2pc",
                        }}
                      >
                        <label className="FormLabel">First Name</label>
                        <input
                          className="FormTextField"
                          type="firstName"
                          name="firstName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.firstName}
                          // value={(values?.fullName).split(' ')[0]}
                        />
                        <div className="ErrorMessage">
                          {errors.firstName && errors.firstName}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dflex row">
                    <div className="dflex row">
                      <div
                        className="dflex column FormFieldContainer"
                        style={{ width: "30%", float: "left" }}
                      >
                        <label className="FormLabel">DOB</label>
                        <input
                          className="FormTextField"
                          type="date"
                          name="dob"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.dob}
                        />
                        <div className="ErrorMessage">
                          {errors.dob && touched.dob && errors.dob}
                        </div>
                      </div>
                      <div
                        className="dflex column FormFieldContainer"
                        style={{
                          width: "30%",
                          float: "right",
                          paddingLeft: "2pc",
                        }}
                      >
                        <label className="FormLabel">Phone</label>
                        <input
                          className="FormTextField"
                          type="phone"
                          name="phone"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phone}
                          disabled={true}
                        />
                        <div className="ErrorMessage">
                          {errors.phone && touched.phone && errors.phone}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dflex row">
                    <div className="dflex row">
                      <div
                        className="dflex column FormFieldContainer"
                        style={{ width: "30%", float: "left" }}
                      >
                        <label className="FormLabel">Gmail</label>
                        <input
                          className="FormTextField"
                          type="gmail"
                          name="gmail"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.gmail}
                        />
                        <div className="ErrorMessage">
                          {errors.gmail && errors.gmail}
                        </div>
                      </div>
                      <div
                        className="dflex column FormFieldContainer"
                        style={{
                          width: "30%",
                          float: "right",
                          paddingLeft: "2pc",
                        }}
                      >
                        <label className="FormLabel">Gov ID</label>
                        <input
                          className="FormTextField"
                          type="govId"
                          name="govId"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.govId}
                        />
                        <div className="ErrorMessage">
                          {errors.govId && errors.govId}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dflex row">
                    <div className="dflex row">
                      <div
                        className="dflex column FormFieldContainer"
                        style={{ width: "20%", float: "left" }}
                      >
                        <label
                          className="FormLabel"
                          style={{ marginLeft: "-32px" }}
                        >
                          Profile Pic
                        </label>
                        <div style={{ paddingLeft: "2pc" }}>
                          <IMImagesTableCell
                            singleImageURL={values?.profilePic}
                          />
                        </div>
                        <div className="ErrorMessage">
                          {errors.profilePic && errors.profilePic}
                        </div>
                      </div>
                      {/* <div
                      className="dflex column FormFieldContainer"
                      style={{
                        width: "20%",
                        float: "left",
                        paddingLeft: "3pc",
                      }}>
                      <label
                        className="FormLabel"
                        style={{ marginLeft: "-53px" }}>
                        Gesture Image
                      </label>

                      <div> */}
                      {/* <IMImagesTableCell
                          singleImageURL={values?.profilePic}
                        /> */}
                      {/* <IMImagesTableCell
                          singleImageURL={userDocument?.verificationPic}
                        />
                        <span>{userDocument?.gestureGiven}</span>
                      </div>
                      <div className="ErrorMessage">
                        {userDocument?.gestureGiven ? null : "Gesture Image Required"}
                      </div>
                    </div> */}
                      {userDocument?.govtIdFront ? (
                        <div
                          className="dflex column FormFieldContainer"
                          style={{ width: "20%", float: "left" }}
                        >
                          <div>
                            <label className="FormLabel">Gov ID Document</label>

                            <div className="" style={{ marginLeft: "14px" }}>
                              {/* <IMImagesTableCell
                            singleImageURL={userDocument?.govtIdBack}
                          /> */}

                              <IMImagesTableCell
                                singleImageURL={userDocument?.govtIdFront}
                              />

                              {/* <IMImagesTableCell
                            singleImageURL={userDocument?.profilePic}
                          /> */}

                              {/* <IMImagesTableCell
                            singleImageURL={userDocument?.verificationPic}
                          /> */}
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="dflex row">
                    <div className="dflex row">
                      <div
                        className="dflex column FormFieldContainer"
                        style={{ width: "30%" }}
                      >
                        <label className="FormLabel">Status</label>
                        <select
                          style={{ height: "40px" }}
                          className="FormTextField"
                          name="verifyProfile"
                          onChange={handleChange}
                          onBlur={handleBlur}
                         
                        >
                          <option value="">Select</option>
                          <option
                            selected={
                              values.verifyProfile == "false" ||
                              values.verifyProfile == false
                                ? "selected"
                                : ""
                            }
                            value="false"
                          >
                            Unverified
                          </option>
                          <option
                            selected={
                              values.verifyProfile == "true" ||
                              values.verifyProfile == true
                                ? "selected"
                                : ""
                            }
                            value="true"
                          >
                            Verified
                          </option>
                        </select>
                        <div className="ErrorMessage" hidden={!issubmitted}>
                          select verified
                        </div>
                      </div>
                      <div style={{ width: "8%" }}></div>
                      {originalData?.userprivate ? 
                      
                    
                      <div
                        className="dflex column FormFieldContainer"
                        style={{ width: "30%" }}
                      >
                        <label className="FormLabel">Select Private Group</label>
                        <select
                          style={{ height: "40px" }}
                          className="FormTextField"
                          name="unassigned"
                        //  value={['lll']}
                        disabled={originalData.userPrivateGroupId ? true :false}
                        onChange={(e)=>{

                          handleChange(e)
                          if (e.target.value == 'selected') {
                            setGroupIsSelected('selected')
                            } else {
                                setGroupIsSelected(e.target.value)
                                   }
                          console.log("wwwww",e.target.value);
                        }}

//                           onClick={(val)=>{
//                           if (val.target.value == 'selected') {
//                             setGroupIsSelected('selected')
//                           } else {
//                             setGroupIsSelected(val.target.value)
//                           }
// console.log("wwwww",val.target.value);
//                           }}
                          // onBlur={handleBlur}
                          // value={'sssss'}
                        >
                           <option value="selected">selected</option>
                          { groupList && groupList.map((val, i)=>{
                            // if (val.gid == originalData.userPrivateGroupId && groupIsSelected=="selected") {
                            //   setGroupIsSelected(val.gid)
                            // }
                            return(

                              <option key={i} selected={
                                val.gid == originalData.userPrivateGroupId 
                                  ? "selected"
                                  : ""
                              } value={val.gid}>{val.groupName}</option>
                            )
                          })}
                        </select>
                        <div className="ErrorMessage" hidden={groupIsSelected == 'selected' ? false : true}>
                          select privte group
                        </div>
                      </div>
                    :null}
                    </div>
                  </div>
                  <div className="dflex row">
                    <div className="dflex row">
                      <div className="dflex column FormActionContainer">
                        <button
                          className="PrimaryButton"
                          type="submit"
                          // disabled={issubmitted}
                        >
                          Save user
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </CardBody>
      </Card>
      <Row>
        <Col md="12">
          <div className="Card">
            <div className="CardHeader">
              <h4 style={{ fontSize: "18px" }} className="MainPanelHeading">
                Total Walk History : {page.length}{" "}
              </h4>
            </div>
            <div className="CardBody">
              <div className="TableContainer">
                <table className="Table" {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps()}>
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                    <tr>
                      {isLoading ? (
                        <td colSpan={groupsColumns.length - 1}>
                          <p>Loading...</p>
                        </td>
                      ) : (
                        <td colSpan={groupsColumns.length - 1}>
                          <p className="PaginationDetails">
                            Showing {page.length} of {data.length} results
                          </p>
                        </td>
                      )}
                    </tr>
                  </tbody>
                </table>
                <div className="Pagination">
                  <div className="LeftPaginationButtons">
                    <button
                      onClick={() => gotoPage(0)}
                      className="PaginationButton"
                      disabled={!canPreviousPage}
                    >
                      <i className="fa fa-angle-double-left"></i>
                    </button>{" "}
                    <button
                      onClick={() => previousPage()}
                      className="PaginationButton"
                      disabled={!canPreviousPage}
                    >
                      <i className="fa fa-angle-left"></i>
                    </button>
                  </div>
                  <div className="CenterPaginationButtons">
                    <span>
                      Page{" "}
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}
                      </strong>{" "}
                    </span>
                    <span>
                      | Go to page:{" "}
                      <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={(e) => {
                          const page = e.target.value
                            ? Number(e.target.value) - 1
                            : 0;
                          gotoPage(page);
                        }}
                        style={{ width: "100px" }}
                      />
                    </span>{" "}
                    <select
                      value={pageSize}
                      onChange={(e) => {
                        setPageSize(Number(e.target.value));
                      }}
                    >
                      {[10, 20, 30, 40, 50].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="RightPaginationButtons">
                    <button
                      onClick={() => nextPage()}
                      className="PaginationButton"
                      disabled={!canNextPage}
                    >
                      <i className="fa fa-angle-right"></i>
                    </button>{" "}
                    <button
                      onClick={() => gotoPage(pageCount - 1)}
                      className="PaginationButton"
                      disabled={!canNextPage}
                    >
                      <i className="fa fa-angle-double-right"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default UpdateUserView;
