import React, { useEffect, useState } from "react";
import { Formik } from 'formik';
import { ClipLoader } from 'react-spinners';
import { Card, CardBody } from "reactstrap";
// import * as datas from "./data";
import GestureTable from "../../generated/users/GestureTable";

import "../../ui/styles.css"
const baseAPIURL = require('../../config').baseAPIURL;
const UserGesture = () => {

    const [isLoading, setIsLoading] = useState(false);
    const createUser = async(data, setSubmitting)=>{
        setIsLoading(true)
        // console.log("data",datas.data);
        // datas.data.forEach(function (item, index) {
        //     // console.log(item, data);
            const response = fetch(baseAPIURL + 'addGesture', {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({...data}) // body data type must match "Content-Type" header
            });
        //   });
        // return;
        setSubmitting(false);
        setIsLoading(false)

        
    }

    useEffect(()=>{
        // test()

     },[])

    //  const test = () =>{
    //      datas.data.forEach(function (item, index) {
    //          //     // console.log(item, data);
    //          const response = fetch(baseAPIURL + 'addGesture', {
    //              method: 'POST', // *GET, POST, PUT, DELETE, etc.
    //              headers: {
    //                  'Accept': 'application/json',
    //                  'Content-Type': 'application/json',
    //              },
    //              body: JSON.stringify({...item, random:index}) // body data type must match "Content-Type" header
    //          });
    //        });
    //      // return;
    //  }
    if (isLoading) {
        return (
            <div className="sweet-loading card">
                <div className="spinner-container">
                    <ClipLoader
                        className="spinner"
                        sizeUnit={"px"}
                        
                        size={50}
                        color={'#123abc'}
                        loading={isLoading}
                    />
                </div>
            </div> 
        )
    }
    return (
        <>
        <Card className="Card FormCard">
            <CardBody>
                <h1>Add New Gesture</h1>
                <Formik
                initialValues={{gestureValue:''}}
                validate={values => {
                    const errors = {};

                    if (!values.gestureValue) {
                        errors.gestureValue = 'Required'
                    }

                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    createUser(values, setSubmitting);
                }}
            >
                {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting
                }) => (
                <form onSubmit={handleSubmit}>

                    <div className="FormFieldContainer">
                        <label className="FormLabel">Gesture Value</label>
                        <input
                            className="FormTextField"
                            type="gestureValue"
                            name="gestureValue"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.gestureValue}
                        />
                        <div className="ErrorMessage">
                            {errors.gestureValue && touched.gestureValue && errors.gestureValue}
                        </div>
                    </div>

                    <div className="FormActionContainer">
                        <button className="PrimaryButton" type="submit" disabled={isSubmitting}>
                            Add Gesture
                        </button>
                    </div>
                </form>
                )}
            </Formik>
            </CardBody>
        </Card>
        <GestureTable/>
        </>
    )
}

export default UserGesture;