import React, { useMemo, useEffect, useState } from "react";
import {
  IMLocationTableCell,
  IMImagesTableCell,
  IMDateTableCell,
} from "../../ui/IMTable";
import { useTable, usePagination } from "react-table";
import { Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";
import { getToken } from "../../../onboarding";
import { filter } from "draft-js/lib/DefaultDraftBlockRenderMap";
// import SearchBar from "./SearchBar";
// import { render } from "react-dom";
const baseAPIURL = require("../../config").baseAPIURL;
const isTemp = [];
const usersColumns = [
  {
    Header: "Full Name",
    accessor: "fullName",
  },
  {
    Header: "First Name",
    accessor: "firstName",
  },
  {
    Header: "Mobile",
    accessor: "phone",
  },
  // {
  //     Header: "Location",
  //     accessor: "location",
  //     Cell: IMLocationTableCell,
  // },
  {
    Header: "Profile Picture",
    accessor: (data) => {
      return <IMImagesTableCell singleImageURL={data.profilePic} />;
    },
  },
  // {
  //     Header: "photos",
  //     accessor: "photos",
  //     Cell: data => (
  //         <IMImagesTableCell imageURLs={data.value} />
  //     )
  // },
  {
    Header: "Verified",
    accessor: (data) => (
      <div>
      <div>{data.verifyProfile == "true" ? "Verified" : " Not verified"}</div>
      <div>{data.userprivate == true && data.unassigned == true ? "Assigned" : data.userprivate == true && data.unassigned == false ? "Unassigned" :null}</div>
      </div>
    ),
    // console.log("hello",data.verifyProfile)},
    // Cell:
  },
  {
    Header: "Actions",
    accessor: "actions",
    Cell: (data) => <ActionsItemView data={data} />,
  },
];

function ActionsItemView(props) {
  const { data } = props;
  const history = useHistory();

  // const handleView = (item) => {
  //     const viewPath = "/admin/user/" + data.row.original.id + '/view'
  //     history.push(viewPath)
  // }

  const handleEdit = (item) => {
    const editPath = "/admin/user/" + item.id + "/update";
    history.push(editPath);
  };

  const handleDelete = async (item) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      const response = await fetch(baseAPIURL + "user/" + item.id, {
        method: "DELETE", // *GET, POST, PUT, DELETE, etc.
      });
      window.location.reload(false);
    }
  };

  // const handleChat = (item) => {
  //     const editPath = "/admin/chat/" + item.id
  //     history.push(editPath)
  // }

  return (
    <div className="inline-actions-container">
      {/* <button onClick={() => handleChat(data.row.original)} type="button" id="tooltip476629793" className="btn-icon btn btn-info btn-sm"><i className="fa fa-comments"></i></button> */}
      {/* <button onClick={() => handleView(data.row.original)} type="button" id="tooltip264453216" className="btn-icon btn btn-info btn-sm"><i className="fa fa-list"></i></button> */}
      <button
        onClick={() => handleEdit(data.row.original)}
        type="button"
        id="tooltip366246651"
        className="btn-icon btn btn-success btn-sm">
        <i className="fa fa-edit"></i>
      </button>
      <button
        onClick={() => handleDelete(data.row.original)}
        type="button"
        id="tooltip476609793"
        className="btn-icon btn btn-danger btn-sm">
        <i className="fa fa-times"></i>
      </button>
      <input className="btn-icon btn btn-danger btn-sm"  id="tooltip476609799" type='checkbox' onChange={(val)=>{isTemp.push({...data.row.original,  ischecked:val.target.checked})}} />
    </div>
  );
}

function UsersListView(props) {
  const listName = "users";

  const [isLoading, setIsLoading] = useState(true);
  const [controlledPageCount, setControlledPageCount] = useState(0);
  const [users, setUsers] = useState([]);
  const [typeOf, setTypeOf] = useState("all");

  const [data, setData] = useState([]);


  const [state, setState] = React.useState({
    search: "",
  });
  const [stateStatus, setStateStatus] = React.useState({
    searchStatus: "",
  });
  const [name, setName] = useState("");
  const columns = useMemo(() => usersColumns, []);
 

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    //pagination
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: users,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    usePagination
  );

  const handleChangeSearchStatusPackage = ({ target: { value } }) => {
    setState({ ...state, search: "" });
    
    
    const keyword = value;
    //   const tempdata = data;
    const t = Object.values(data);
   
    // console.log("asfjsbdf",typeof keyword, keyword);
    // if (keyword === true) {
      
    // }
    
    if (keyword) {
      var results;
      // const results = t?.filter((user) => {
      //   // console.log("if user", user);
      //   // const storeSub = [];
      //   return user?.verifyProfile
      //     ?.toLowerCase()
      //     .includes(keyword.toLowerCase());
      // });
if (keyword == 'isTrue') {
  results = t?.filter(user => user.unassigned == true && user.userprivate == true);
} else if (keyword == 'isFalse') {
  results = t?.filter(user => user.unassigned == false && user.userprivate == true);
} else {
    results = t?.filter((user) => {
        // console.log("if user", user);
        // const storeSub = [];
        return user?.verifyProfile
          ?.toLowerCase()
          .includes(keyword.toLowerCase());
      });
}

      setUsers(results);
    setStateStatus({ ...stateStatus, searchStatus: value});

    } else {
      // console.log("hello rajeshsssss", data);
      setUsers(data);
    setStateStatus({ ...stateStatus, searchStatus: value});

    }
    setName(keyword);
    // handleChangeSearchPackage({ target: { value } });
  };

  const handleChangeSearchPackage = ({ target: { value } }) => {
    setStateStatus({ ...stateStatus, searchStatus: "Select All" });
   

    const keyword = value;
    const t = Object.values(data);
    if (keyword) {
      const results = t?.filter((user) => {
        console.log("if user", user);
        return (
          user?.fullName?.toLowerCase().includes(keyword.toLowerCase()) ||
          user?.firstName?.toLowerCase().includes(keyword.toLowerCase()) ||
          user?.phone?.toLowerCase().includes(keyword.toString().toLowerCase())
        );
      });

      setUsers(results);
      if (keyword !== "true" || keyword !== "false") {
        console.log("second");
        setState({ ...state, search: value });
      }
    } else {
      //   window.location.reload();
      //   setFoundUsers(users);
      setUsers(data);
      if (keyword !== "true" || keyword !== "false") {
        console.log("second");
        setState({ ...state, search: value });
      }
      //   setRows(users);
      //   setState({ ...state, search: value })
    }
    setName(keyword);
  };

  useEffect(() => {
    const token = getToken();
    const config = {
      headers: { Authorization: token },
    };

    const extraQueryParams = null;
    setIsLoading(true);

    fetch(
      baseAPIURL + listName + (extraQueryParams ? extraQueryParams : ""),
      config
    )
      .then((response) => response.json())
      .then((data) => {
        const users = data.users;
        setData(users);

        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const startRow = pageSize * pageIndex;
    const endRow = startRow + pageSize;

    setUsers(data.slice(startRow, endRow));
    setControlledPageCount(Math.ceil(data.length / pageSize));
  }, [pageIndex, pageSize, data]);

  //   const [search, setSearch] = React.useState('');

  //   const handleSearch = (event) => {
  //     setSearch(event.target.value);
  //   };

  //   const usersdata = {
  //     nodes: page.filter((item) =>
  //       item.firstName.includes(search)
  //     ),
  //   };
  const multiDeleteUser =()=>{
    
    function getUniqueListBy(arr, key) {
      return [
        ...new Map(
          arr.map(item => [item[key], item]),
        ).values(),
      ];
    }
    const uniqData = getUniqueListBy(isTemp, 'uid');
    const filters =  uniqData.filter(val=>val.ischecked == true)
    if (filters.length == 0) {
      return alert('Please select item.')
     }
    if (window.confirm("Are you sure you want to delete selected item?")) {
     
     
    Promise.all(
      filters.map(async (vals) => {
        return fetch(baseAPIURL + "user/" + vals.id, {
          method: "DELETE", // *GET, POST, PUT, DELETE, etc.
        });
      })
    )
      .then((message) => {
        console.log("Messages sent!", message);
       
      })
      .catch((err) => {
       
        console.log("eeeeeeeeeeeeeeeeeeeeeeeee", err);
      });
      window.location.reload(false);
    }

}

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <div className="Card">
              <div className="CardHeader">
                {/* <a className="Link AddLink" href="/admin/user/add">Add New</a> */}
                {/* <a className="Link AddLink" href="/admin/user/add"><i className="fa fa-user-plus"></i></a> */}
                <h4 className="MainPanelHeading">Users</h4>
              </div>
              <div className="dflex row" style={{ width: "95%" }}>
                <div className="SearchBox dflex column">
                  <label>Search</label>
                  <input
                    id="search"
                    type="text"
                    value={state.search}
                    onChange={handleChangeSearchPackage}
                  />
                </div>

                <div style={{ width: "70%", textAlign: "-webkit-right" }}>
                  <div
                    style={{ width: "25%" }}
                    className="dflex column FormFieldContainer">
                    <label className="FormLabel" style={{ textAlign: "left" }}>
                      Status
                    </label>
                    <select
                      style={{ height: "23px" }}
                      className="FormTextField"
                      value={stateStatus.searchStatus}
                      // id="searchStatusId"
                      onChange={handleChangeSearchStatusPackage}>
                      <option value="">Select All</option>
                      <option value="true">Verified</option>
                      <option value="false">Not verified</option>
                      <option value={"isFalse"}>Unassigned</option>
                      <option value={"isTrue"}>Assigned</option>
                    </select>
                  </div>
                </div>
               
                
                <button
                 style={{ width: "10%",marginTop:'15px' }}
        onClick={() => multiDeleteUser()}
        type="button"
        id="tooltip476609793"
        className="btn-icon btn btn-danger btn-sm">
        <i className="fa fa-trash" style={{fontSize:'20px'}}></i>
      </button>
                
              </div>
              <div className="CardBody">
                <div className="TableContainer">
                  <table className="Table" {...getTableProps()}>
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()}>
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {page.map((row, i) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                      <tr>
                        {isLoading ? (
                          <td colSpan={usersColumns.length - 1}>
                            <p>Loading...</p>
                          </td>
                        ) : (
                          <td colSpan={usersColumns.length - 1}>
                            <p className="PaginationDetails">
                              Showing {page.length} of {data.length} results
                            </p>
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                  <div className="Pagination">
                    <div className="LeftPaginationButtons">
                      <button
                        onClick={() => gotoPage(0)}
                        className="PaginationButton"
                        disabled={!canPreviousPage}>
                        <i className="fa fa-angle-double-left"></i>
                      </button>{" "}
                      <button
                        onClick={() => previousPage()}
                        className="PaginationButton"
                        disabled={!canPreviousPage}>
                        <i className="fa fa-angle-left"></i>
                      </button>
                    </div>
                    <div className="CenterPaginationButtons">
                      <span>
                        Page{" "}
                        <strong>
                          {pageIndex + 1} of {pageOptions.length}
                        </strong>{" "}
                      </span>
                      <span>
                        | Go to page:{" "}
                        <input
                          type="number"
                          defaultValue={pageIndex + 1}
                          onChange={(e) => {
                            const page = e.target.value
                              ? Number(e.target.value) - 1
                              : 0;
                            gotoPage(page);
                          }}
                          style={{ width: "100px" }}
                        />
                      </span>{" "}
                      <select
                        value={pageSize}
                        onChange={(e) => {
                          setPageSize(Number(e.target.value));
                        }}>
                        {[10, 20, 30, 40, 50].map((pageSize) => (
                          <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="RightPaginationButtons">
                      <button
                        onClick={() => nextPage()}
                        className="PaginationButton"
                        disabled={!canNextPage}>
                        <i className="fa fa-angle-right"></i>
                      </button>{" "}
                      <button
                        onClick={() => gotoPage(pageCount - 1)}
                        className="PaginationButton"
                        disabled={!canNextPage}>
                        <i className="fa fa-angle-double-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

// export default UsersListView,

export default UsersListView;
