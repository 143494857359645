import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  InfoWindow,
  Marker
} from "@react-google-maps/api";

import { getToken } from "../../../onboarding";

const baseAPIURL = require('../../config').baseAPIURL;

function AddViewMap() {
  const [activeMarker, setActiveMarker] = useState(null);
  const [data, setData] = useState({});
  const [business1, setBusiness] = useState([]);
  
  const center = {
    lat: 56.54655432928713,
    lng: -115.28172212741737
  }
  useEffect(() => {
    const listName = "business";
    const token = getToken();
    const config = {
      headers: { 'Authorization': token }
    };
    const extraQueryParams = null
    const formData = new FormData();

    fetch(baseAPIURL + listName + (extraQueryParams ? extraQueryParams : ""), config)
      .then(response => response.json())
      .then(data => {
        const business = [{"saferPointName": data.business[0].saferPointName,
        "saferPointAddress": data.business[0].saferPointAddress,'order_lat':data.business[0].businessLat,'order_lng':data.business[0].businessLng}];
        setBusiness(business);
      })
      .catch(err => { console.log(err) });
  }, []);

  let wayptMarker = [];
  if(business1.length > 0){
    business1.map((item, index, arr) => {
      wayptMarker.push(item);
    });
  }

  let markers =
    business1 !== undefined &&
    business1 &&
    wayptMarker.map((item, index) => ({
      id: index + 1,
      name: item.saferPointName + " - " + item.saferPointAddress,
      position: {
        lat: Number(item && item.order_lat, 10),
        lng: Number(item && item.order_lng, 10)
      }
    }));

  const [markers11, setMarkers11] = useState(markers);
  
  const test11 = item => {
    item.saferPointName = "SaferPoint";
    item.saferPointAddress = "123";
    wayptMarker.push(item);

    markers = business1 !== undefined &&
      business1 &&
      wayptMarker.map((item, index) => ({
        id: index + 1,
        name: item.saferPointName + " - " + item.saferPointAddress,
        position: {
          lat: Number(item && item.order_lat, 10),
          lng: Number(item && item.order_lng, 10)
        }
      }));
    setMarkers11(markers)
  }
  console.log('ssssss', markers11);
  const handleActiveMarker = marker => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleOnLoad = map => {
    const bounds = new window.google.maps.LatLngBounds();
    markers11 && markers11.forEach(({ position }) => bounds.extend(position));
    map.fitBounds(bounds);
  };

  return (
    <GoogleMap center={center}  
      onLoad={handleOnLoad}
      onClick={cord => {
        test11({order_lat:cord.latLng.lat(), order_lng:cord.latLng.lng()})
      }
      }
      mapContainerStyle={{ width: "100%", height: "88vh" }}
    >
      {markers11 &&
        markers11.map(({ id, name, position }) => (
          <Marker
            key={id}
            position={position}
            onClick={() => handleActiveMarker(id)}
            label={{ text: `${id}`, color: "white" }}
          >
            {activeMarker === id ? (
              <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                <div>{name}</div>
              </InfoWindow>
            ) : null}
          </Marker>
        ))}
    </GoogleMap>
  );
}

export default AddViewMap;