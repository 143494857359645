import React, { useMemo, useEffect, useState } from "react";
import {
  IMLocationTableCell,
  IMImagesTableCell,
  IMDateTableCell,
} from "../../ui/IMTable";
import { useTable, usePagination } from "react-table";
import { Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";
import { getToken } from "../../../onboarding";
import { useParams } from "react-router-dom";
import { IMToggleSwitchComponent } from "../../../common";

const baseAPIURL = require("../../config").baseAPIURL;

const groupsColumns = [
  {
    Header: "Group Name",
    accessor: "groupName",
  },
  {
    Header: "Group Code",
    accessor: "groupCode",
  },
  {
    Header: "Access",
    accessor: "access",
    Cell: (data) => <AccessStatusView data={data} />,
  },
  {
    Header: "Group Logo",
    accessor: (data) => {
      return <IMImagesTableCell singleImageURL={data.profilePictureURL} />;
    },
  },
  {
    Header: "Actions",
    accessor: "actions",
    Cell: (data) => <ActionsItemView data={data} />,
  },
];

function ActionsItemView(props) {
  const { data } = props;
  const history = useHistory();
  const handleView = (item) => {
    const viewPath = "/client/groupsDetails/" + data.row.original.gid + "/view";
    // const viewPath = "/client/groupsDetails";
    history.push(viewPath);
  };

  return (
    <div className="inline-actions-container">
      <button
        onClick={() => handleView(data.row.original)}
        type="button"
        id="tooltip264453216"
        className="btn-icon btn btn-info btn-sm"
      >
        <i className="fa fa-list"></i>
      </button>
    </div>
  );
}

function AccessStatusView(props) {
  const { data } = props;
  return (
    <div className="FormFieldContainer">
      {/* <IMToggleSwitchComponent isChecked={data.row.values.access === 'private'} /> */}
      <label className="FormLabelStyling" style={{ padding: "0px" }}>
        {data.row.values.access === "private" ? (
          <label>Private</label>
        ) : (
          <label>Public</label>
        )}
      </label>
    </div>
  );
}

function ClientGroupLists(props) {
  const listName = "clientGroupList";
  const [isLoading, setIsLoading] = useState(true);
  const [controlledPageCount, setControlledPageCount] = useState(0);
  const [groups, setUsers] = useState([]);
  const [data, setData] = useState([]);
  const columns = useMemo(() => groupsColumns, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    //pagination
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: groups,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    usePagination
  );

  useEffect(() => {
    const token = getToken();
    const getuid = localStorage.getItem("uid");
    const extraQueryParams = null;
    setIsLoading(true);

    const formData = new FormData();
    formData.append("uid", getuid);
    fetch(baseAPIURL + listName + (extraQueryParams ? extraQueryParams : ""), {
      method: "POST",
      headers: { Authorization: token },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        const groups = data.groups;
        if (groups) {
          setData(groups);
        } else {
          setData([]);
        }

        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [state, setState] = React.useState({
    search: "",
  });
  const [stateStatus, setStateStatus] = React.useState({
    searchStatus: "",
  });
  useEffect(() => {
    const startRow = pageSize * pageIndex;
    const endRow = startRow + pageSize;

    if (
      state.search ||
      stateStatus.searchStatus === "public" ||
      stateStatus.searchStatus === "private"
    ) {
      setUsers(groups?.slice(startRow, endRow));
    } else {
      setUsers(data?.slice(startRow, endRow));
    }
    setControlledPageCount(Math.ceil(data?.length / pageSize));
  }, [pageIndex, pageSize, data]);

  const [name, setName] = useState("");

  const handleChangeSearchStatusPackage = ({ target: { value } }) => {
    console.log("handleChangeSearchStatusPackage");
    setState({ ...state, search: "" });
    const keyword = value;
    //   const tempdata = data;
    const t = Object.values(data);
    if (keyword) {
      const results = t?.filter((user) => {
        // const storeSub = [];
        return user?.access?.toLowerCase().includes(keyword.toLowerCase());
      });
      setUsers(results);
      setStateStatus({ ...stateStatus, searchStatus: value });
    } else {
      // console.log("hello rajeshsssss", data);
      setUsers(data);
      setStateStatus({ ...stateStatus, searchStatus: value });
    }
    setName(keyword);
  };

  const handleChangeSearchPackage = ({ target: { value } }) => {
    // setStateStatus({ ...stateStatus, searchStatus: "Select All" });
    console.log("handleChangeSearchPackage");

    const keyword = value;
    const t = Object.values(data);
    if (keyword) {
      if (
        stateStatus.searchStatus === "public" ||
        stateStatus.searchStatus === "private"
      ) {
        const results = t?.filter((user) => {
          return (
            (user?.groupName?.toLowerCase().includes(keyword.toLowerCase()) &&
              user?.access
                ?.toLowerCase()
                .includes(stateStatus.searchStatus.toLowerCase())) ||
            (user?.groupCode?.toLowerCase().includes(keyword.toLowerCase()) &&
              user?.access
                ?.toLowerCase()
                .includes(stateStatus.searchStatus.toLowerCase()))
          );
        });
        setUsers(results);
      } else {
        const results = t?.filter((user) => {
          return (
            user?.groupName?.toLowerCase().includes(keyword.toLowerCase()) ||
            user?.groupCode?.toLowerCase().includes(keyword.toLowerCase())
          );
        });
        setUsers(results);
      }

      if (keyword !== "public" || keyword !== "private") {
        console.log("second");
        setState({ ...state, search: value });
      }
    } else {
      if (
        stateStatus.searchStatus === "public" ||
        stateStatus.searchStatus === "private"
      ) {
        const results = t?.filter((user) => {
          // const storeSub = [];
          return user?.access
            ?.toLowerCase()
            .includes(stateStatus.searchStatus.toLowerCase());
        });
        setUsers(results);
      } else {
        setUsers(data);
      }
      if (keyword !== "public" || keyword !== "private") {
        setState({ ...state, search: value });
      }
      //   setRows(users);
      //   setState({ ...state, search: value })
    }
    setName(keyword);
  };

  return (
    <>
      {page?.length == 0 && isLoading == false ? (
        <h4 className="MainPanelHeading">No Groups</h4>
      ) : (
        <div className="content">
          <Row>
            <Col md="12">
              <div className="Card">
                <div className="CardHeader">
                  <h4 className="MainPanelHeading">Groups</h4>
                </div>
                <div className="dflex row" style={{ width: "80%" }}>
                  <div className="SearchBox dflex column">
                    <label>Search</label>
                    <input
                      id="search"
                      type="text"
                      value={state.search}
                      onChange={handleChangeSearchPackage}
                    />
                  </div>

                  <div style={{ width: "70%", textAlign: "-webkit-right" }}>
                    <div
                      style={{ width: "25%" }}
                      className="dflex column FormFieldContainer"
                    >
                      <label
                        className="FormLabel"
                        style={{ textAlign: "left" }}
                      >
                        Status
                      </label>
                      <select
                        style={{ height: "23px" }}
                        className="FormTextField"
                        value={stateStatus.searchStatus}
                        // id="searchStatusId"
                        onChange={handleChangeSearchStatusPackage}
                      >
                        <option value="">Select All</option>
                        <option value="public">Public</option>
                        <option value="private">Private</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="CardBody">
                  <div className="TableContainer">
                    <table className="Table" {...getTableProps()}>
                      <thead>
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th {...column.getHeaderProps()}>
                                {column.render("Header")}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                          prepareRow(row);
                          // console.log('row',row)
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell) => {
                                return (
                                  <td {...cell.getCellProps()}>
                                    {cell.render("Cell")}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                        <tr>
                          {isLoading ? (
                            <td colSpan={groupsColumns.length - 1}>
                              <p>Loading...</p>
                            </td>
                          ) : (
                            <td colSpan={groupsColumns.length - 1}>
                              <p className="PaginationDetails">
                                Showing {page.length} of {data.length} results
                              </p>
                            </td>
                          )}
                        </tr>
                      </tbody>
                    </table>
                    <div className="Pagination">
                      <div className="LeftPaginationButtons">
                        <button
                          onClick={() => gotoPage(0)}
                          className="PaginationButton"
                          disabled={!canPreviousPage}
                        >
                          <i className="fa fa-angle-double-left"></i>
                        </button>{" "}
                        <button
                          onClick={() => previousPage()}
                          className="PaginationButton"
                          disabled={!canPreviousPage}
                        >
                          <i className="fa fa-angle-left"></i>
                        </button>
                      </div>
                      <div className="CenterPaginationButtons">
                        <span>
                          Page{" "}
                          <strong>
                            {pageIndex + 1} of {pageOptions.length}
                          </strong>{" "}
                        </span>
                        <span>
                          | Go to page:{" "}
                          <input
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={(e) => {
                              const page = e.target.value
                                ? Number(e.target.value) - 1
                                : 0;
                              gotoPage(page);
                            }}
                            style={{ width: "100px" }}
                          />
                        </span>{" "}
                        <select
                          value={pageSize}
                          onChange={(e) => {
                            setPageSize(Number(e.target.value));
                          }}
                        >
                          {[10, 20, 30, 40, 50].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                              Show {pageSize}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="RightPaginationButtons">
                        <button
                          onClick={() => nextPage()}
                          className="PaginationButton"
                          disabled={!canNextPage}
                        >
                          <i className="fa fa-angle-right"></i>
                        </button>{" "}
                        <button
                          onClick={() => gotoPage(pageCount - 1)}
                          className="PaginationButton"
                          disabled={!canNextPage}
                        >
                          <i className="fa fa-angle-double-right"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}

export default ClientGroupLists;
